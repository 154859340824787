<template>
  
</template>

<script>

import appUtils from '@/composables/utils'
import Card from 'src/components/Cards/Card.vue'
import Loading from '../../layout/Loading'
import AgendaService from '../../service/AgendaService'
import CamposFormulario from '../comuns/CamposFormulario'

export default {
  props: ['idCredenciamento'],
  components: {
    Card,
    CamposFormulario,
    Loading
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      credenciamento: null,
      obsReprovacao: null,
      reprovando: false,
      listaTags: [],
      tags: []
    }
  },
  mounted() {

    const ep = this.$route.query.ep
    const ch = this.$route.query.ch
    
    this.$router.push({ name: 'Credenciamento', params: { empresa: ep}, query: { tp: btoa('PF'), ch: ch, dest: btoa('Credenciamentoi') } });

  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("carregaDados");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.idCredenciamento,
        "comTags": true
      }

      this.buscarTags()

      AgendaService.abrirCredenciamento(this.http, dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.credenciamento = retorno.obj
            this.credenciamento.formulario.campos.filter((c) => { return c.tipo == 'CNPJ' })
                    .forEach(c => { 
                      this.alterouCnpj(
                        { 'campo': c.nome, 'cnpj': c.valor }
                      )
                    })
            this.atualizarTags()
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("carregaDados");
        }).catch((error) => {
          desativarShow("carregaDados");
          toast(error, 'Falha', 'danger')
        });
    },
    
  }

}
</script>

<style>
.box-color {
  border-width: 0;
  border-style: solid;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  width: 100px;
  cursor: pointer;

}
</style>