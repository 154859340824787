import AuthService from './auth.service';
const user = JSON.parse(localStorage.getItem('dadosUsuario'));
const conf = JSON.parse(localStorage.getItem('confEscalaMedicaJS'));
const initialState = user
  ? { status: { loggedIn: true }, user, empresa: user.grupo.empresa }
  : { status: { loggedIn: false }, user: null, empresa: null, conf : false };


export const auth = {
  namespaced: true,
  state: initialState,
  getters: {    
    hasAccess: (state) => (menu) => {
        return state.user && state.user.grupo.menus.filter(m => m.descricao === menu).length > 0;
    },                
    hasPermission: (state) => (menu, permissao, nivel) => {
      let men = state.user ? state.user.grupo.menus.filter(m => m.descricao === menu) : null;      
      let perm = men && men.length && men[0].permissoes ? men[0].permissoes.filter(p => p.permissao === permissao) : null;
      return perm && perm.length > 0 ? (nivel === '' || perm[0].nivel === nivel) : true
    },
    hasPermissionWith: (state) => (menu, permissao, nivel) => {
      let men = state.user ? state.user.grupo.menus.filter(m => m.descricao === menu) : null;      
      let perm = men && men.length && men[0].permissoes ? men[0].permissoes.filter(p => p.permissao === permissao) : null;                  
      return perm && perm.length > 0 ? (nivel === '' || perm[0].nivel === nivel) : false
    },
    hasMenu: (state) => (posicao) => {      
      return state.user && state.user.grupo.menus.filter(m => m.posicao === posicao).length > 0;
    },
    userName (state) {
        return state.user ? state.user.nome : ''
    },
    userGroupName (state) {
      return state.user ? state.user.grupo.descricao : ''
    },
    logoEmpresa(state) {
      if (state.empresa) {
        return state.empresa.logo
      } else if (!state.status.loggedIn) {
        return 'https://s3.sa-east-1.amazonaws.com/escalamedica.img/logo-escala-medica.png'
      } else {
        return null
      }
      //return state.empresa ? state.empresa.logo : null //'https://acesso.jsoftware.com.br/img/escala-medica-horizontal.png'
    },
    versao(state) {      
      return state.user ? state.user.versaoWeb : "";      
    },
    empresa(state) {            
        return state.empresa ? state.empresa : null
    },
    urlPadrao(state, getters) {
      var url = state.user.urlPadrao
      if (url) {
        return url
      } else if (getters.hasAccess('AGENDA')) {
        url = '/admin/agenda'
      } else if (getters.hasAccess('CREDENCIAMENTO')) {
        url = '/admin/credenciamentoi'
      } else {
        url = '/home'
      }
      return url
    }
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        userRes => {                    
          commit('loginSuccess', userRes);
          return Promise.resolve(userRes);
        },
        error => {          
          commit('loginFailure');
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {      
      AuthService.logout();
      commit('logout');
    },
    loadEmpresa({ commit }, empresa) {        
      commit('loadEmpresaSuccess', empresa);
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    }
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
      state.empresa = user.grupo.empresa;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
      state.empresa = null;
    },
    loadEmpresaSuccess(state, empresa) {                        
      state.empresa = empresa;
    },
    registerSuccess(state) {
      state.status.loggedIn = false;
    },
    registerFailure(state) {
      state.status.loggedIn = false;
    }
  }
}