<template>
  <div>
    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir() }">
      <template v-slot:texto>Confirma exclusão da especialização</template>
    </confirma>

    <div>

      <b-row class="mx-0" v-if="showTipo">
        <b-col class="col-12">
          <b-form-group v-if="espec.editing == 'S'" label="Tipo de especialização" label-for="input-tipo" class="mb-0">
            <b-form-select id="input-tipo" v-model="espec.idTipo" value-field="id"
              @change="alterarTipo(espec.idTipo)" text-field="descricao" :options="tiposEspecializacao"
              size="sm">
            </b-form-select>
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Tipo: </span> {{ espec.dscTipo }}</p>
        </b-col>
      </b-row>

      <b-row class="mx-0">

        <b-col class="col-12">
          <b-form-group v-if="espec.editing == 'S'" label="Título" label-for="input-titulo" class="mb-0">
            <b-form-input id="input-titulo" type="text" label="Título" placeholder="Título" v-model="espec.titulo" />
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Títutlo: </span> {{ espec.titulo }}</p>
        </b-col>

        <b-col class="col-12 pr-0">
          <b-form-group v-if="espec.editing == 'S'" label="Instituição" label-for="input-instituicao" class="mb-0">
            <b-form-input :readonly="espec.editing == 'N'" id="input-instituicao" type="text" label="Instituição"
              placeholder="Instituição" v-model="espec.instituicao" />
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Instituição: </span> {{ espec.instituicao }}</p>
        </b-col>

      </b-row>

      <b-row class="mx-0">

        <b-col class="col-12 col-md-3" v-if="espec.idTipo == 4">
          <b-form-group v-if="espec.editing == 'S'" class="mb-0" label="Especialidade" label-for="input-especialidade" >
            <v-select id="input-especialidade" v-model="espec.idEspecialidade" :options="listaEspecialidade"
              :reduce="especialidade => especialidade.id" label="descricao" placeholder="Especialidade">
              <template #no-options>
                Nenhuma especialidade encontrada
              </template>
            </v-select>
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Especialidade: </span> {{ espec.dscEspecialidade}}</p>
        </b-col>

        <b-col class="col-12 col-md-3" v-if="espec.idTipo == 4">
          <b-form-group v-if="espec.editing == 'S'" label="UF / RQE" label-for="input-rqe" class="mb-0">
            <b-row class="m-0 p-0">
              <div class="col-5 m-0 p-0">
                <b-form-select ref="input-ufrqe" v-model="espec.ufRqe" :options="optionsUF" value-field="value"
                  text-field="value"></b-form-select>
              </div>
              <div class="col-7 m-0 py-0 pr-0">
                <b-form-input id="input-rqe" type="number"
                  onkeydown="javascript: return event.keyCode == 69 ? false : true" label="RQE" placeholder="Nº RQE"
                  v-model="espec.rqe" />
              </div>
            </b-row>
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">RQE: </span> {{ espec.rqe }}</p>
        </b-col>

        <b-col class="col-12 col-md-2" v-if="espec.idTipo == 5">
          <b-form-group v-if="espec.editing == 'S'" class="mb-0" label="Nível" label-for="input-nivel">
            <b-form-select id="input-nivel" v-model="espec.nivel" :options="niveisEspecializacao" size="md">
            </b-form-select>
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Nível: </span> {{ espec.nivel }}</p>
        </b-col>

        <b-col class="col-12 col-md-3">
          <b-form-group v-if="espec.editing == 'S'" class="mb-0" label="Situação" label-for="input-situacao">
            <b-form-select id="input-situacao" v-model="espec.idSituacao" value-field="id" text-field="descricao"
              :options="situacoesEspecializacao" size="md">
            </b-form-select>
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Situação: </span> {{ espec.dscSituacao }}</p>
        </b-col>

        <b-col class="col-12 col-md-3">
          <b-form-group v-if="espec.editing == 'S'" class="mb-0" :label="labelDataObtecao" label-for="input-data-obtencao">
            <b-form-input id="input-data-obtencao" v-mask="'##/####'" label="Obtenção" placeholder="MM/yyyy"
              v-model="espec.dtObtencao" size="sm">
            </b-form-input>
          </b-form-group>
          <p v-else class="text-truncate"><span class="font-weight-bold">Data obtenção: </span> {{ espec.dtObtencao }}</p>
        </b-col>        

      </b-row>

      <b-row class="mx-0">
        <b-col class="col-12">
          <base-input v-if="espec.documentos && espec.documentos.length" label="Documento de comprovação" class="mb-0">

            <div class="row m-0" v-if="!leitura && campo && campo.conf && campo.conf.ajudaDocumento">
              <div class="col-md-12 px-0 pt-0 pb-1">
                <span class="texto-ajuda" v-html="campo.conf.ajudaDocumento.texto"> </span>
              </div>
            </div>
            
              <b-list-group v-for="(doc, index) in espec.documentos" :key="index">
                <b-list-group-item button class="d-flex justify-content-between align-items-center mt-1 pb-0 pt-1">
                  <b-form-file v-if="espec.editing == 'S' && !doc.nome" capture size="sm" browse-text="Procurar"
                  :id="campo.nome + '#' + espec.id.toString() + '#' + doc.id" :state="Boolean(fileSelecionado)"
                  :placeholder="doc.titulo ? doc.titulo : doc.nome" drop-placeholder="Arraste o arquivo aqui..."  accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" @change="submitFile">
                  </b-form-file>                
                  <b-link v-if="doc.nome" class="text-primary text-truncate" @click="abrirArquivo(doc.id)">
                    {{ doc.nome }}
                  </b-link>
                  <b-icon v-if="!leitura && doc.nome" icon="trash" variant="danger" class="ml-2 border" @click="removerDocumento(doc.id)"></b-icon>
                </b-list-group-item>
              </b-list-group>           

            <div class="mt-1" v-if="false">
              <b-list-group v-for="(doc, index) in espec.documentos" :key="index">
                <b-list-group-item button class="d-flex justify-content-between align-items-center mt-1">
                  <b-link class="text-primary text-truncate" @click="abrirArquivo(doc.id)">
                    {{ doc.nome }}
                  </b-link>                  
                  <b-icon v-if="!leitura" icon="trash" variant="danger" class="ml-2 border" @click="removerDocumento(doc.id)"></b-icon>
                </b-list-group-item>
              </b-list-group>
            </div>

          </base-input>
        </b-col>
      </b-row>

      <b-row class="pt-1">
        <b-col>
          <b-button v-if="showSave && espec.editing === 'N'" type="submit" variant="primary" class="btn-fill ml-2"
            size="sm" @click.prevent="editar(espec.id)">
            Editar
          </b-button>
          <b-button v-if="showSave && espec.editing === 'S'" type="submit" variant="primary" class="btn-fill ml-2"
            size="sm" @click.prevent="salvar(espec.id)">
            Salvar
          </b-button>
          <b-button v-if="showExcluir && !leitura" type="submit" variant="danger" class="btn-fill ml-2" size="sm"
            @click.prevent="confirmaExcluir(espec.id)">
            Excluir
          </b-button>
          <b-button v-if="showSave && espec.editing === 'S'" type="submit" variant="danger" class="btn-fill ml-2"
            size="sm" @click.prevent="cancelar(espec.id)">
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  
<script>
import getUF from '@/composables/getUF'
import appUtils from '@/composables/utils'
import axios from 'axios'
import Card from 'src/components/Cards/Card.vue'
import funcao from "../../components/Funcao"
import AgendaService from '../../service/AgendaService'
import { BASE_CONSTANTS } from '../../service/config'
import Confirma from './Confirma'

export default {
  props: ['espec', 'showSave', 'showExcluir', 'leitura', 'campo'],
  components: {
    Card,
    Confirma,
  },
  data() {
    return {
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      id: null,
      fileSelecionado: null,
      tiposEspecializacao: [],
      listaEspecialidade: [],
      niveisEspecializacao: [],
      situacoesEspecializacao: [],
      copia: null,
      optionsUF: [],
      showTipo: true,
    }
  },
  computed: {

  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })

    this.inicializar()
    this.carregaDados()

  },
  methods: {
    inicializar() {
      this.showTipo = !this.campo || !this.campo.conf || !this.campo.conf.tiposEspecializacao || this.campo.conf.tiposEspecializacao.length > 1
      this.optionsUF = getUF()
      this.buscarTiposEspecializacao()
      this.buscarEspecialidades()
      this.buscarNiveisEspecializacao()
      this.buscarSituacoesEspecializacao()
    },
    buscarTiposEspecializacao() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarTiposEspecializacao");

      let dadosConsulta = {
        "identificadores": this.campo && this.campo.conf && this.campo.conf.tiposEspecializacao && this.campo.conf.tiposEspecializacao.length > 0 ? this.campo.conf.tiposEspecializacao : null                
      }

      AgendaService.listaTipoEspecializacao(dadosConsulta)
        .then(resposta => {
          this.tiposEspecializacao = resposta.data.obj
          desativarShow("buscarTiposEspecializacao");
        }).catch(error => {
          desativarShow("buscarTiposEspecializacao");
        })

    },
    buscarEspecialidades() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarEspecialidades");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa
      }

      AgendaService.listaEspecialidade(dadosConsulta)
        .then(resposta => {
          this.listaEspecialidade = resposta.data.obj
          desativarShow("buscarEspecialidades");
        }).catch(error => {
          desativarShow("buscarEspecialidades");
        })

    },
    buscarNiveisEspecializacao() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarNiveisEspecializacao");

      AgendaService.listaNivelEspecializacao(this.http)
        .then(resposta => {
          this.niveisEspecializacao = resposta.data.obj
          desativarShow("buscarNiveisEspecializacao");
        }).catch(error => {
          desativarShow("buscarNiveisEspecializacao");
        })

    },
    buscarSituacoesEspecializacao() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarSituacoesEspecializacao");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idTipo": 3,
        "identificadores": this.campo && this.campo.conf && this.campo.conf.situacoesEspecializacao && this.campo.conf.situacoesEspecializacao.length > 0 ? this.campo.conf.situacoesEspecializacao : null        
      }

      AgendaService.recuperaSituacoesGeral(dadosConsulta)
        .then(resposta => {
          this.situacoesEspecializacao = resposta.data.obj
          desativarShow("buscarSituacoesEspecializacao");
        }).catch(error => {
          desativarShow("buscarSituacoesEspecializacao");
        })

    },
    editar(id) {

      this.$emit('editar', id)

      /*if (this.showSave) {
        this.especializacoes.forEach((e) => {
          e.editing = 'N'
        })
      }

      let item = this.especializacoes.find(e => e.id === id)
      this.copia = { ...item }
      item.editing = 'S'*/

    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarCredenciamentos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idSituacao": this.idSituacao,
        "tipoData": this.tipoFiltroData,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
      }

      AgendaService.listaCredenciamento(dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          desativarShow("listarCredenciamentos");
        })
        .catch((err) => {
          desativarShow("listarCredenciamentos");
        });

    },
    salvar(id) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("salvar");

      let item = this.especializacoes.find(e => e.id === id)

      AgendaService.saveEspecializacao(this.http, item)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let index = this.especializacoes.indexOf(item);
            this.especializacoes.splice(index, 1, retorno.obj);
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("salvar");
        }).catch((error) => {
          desativarShow("salvar");
          toast(error, 'Falha', 'danger')
        });

    },
    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    cancelar(id) {
      this.$emit('cancelar', id)
    },
    excluir() {
      if (this.id < 0) {
        this.cancelar(this.id)
      } else {

        const { toast, ativarShow, desativarShow } = appUtils(this)
        ativarShow("excluir");

        AgendaService.deleteEspecializacao(this.http, this.id).then(resposta => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.$emit('excluir', this.id)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("excluir");
        }).catch(error => {
          desativarShow("excluir");
          toast(error, "Falha", "danger")
        })
      }

    },
    atualizarRegistro(entity) {
      let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.items.splice(index, 1, entity);
      } else {
        this.items.push(entity);
      }
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    },
    abrirCredenciamento(record, index) {
      this.editar(record.id)
    },
    strDateFormat(dataStr, format) {
      if (dataStr !== null) {
        let data = new Date(dataStr + " (UTC-3)")
        return dateToStr(data, format);
      } else {
        return ""
      }
    },
    submitFile(event) {

      var idDoc = Number(event.target.id.split('#')[2])
      var espec = this.espec
      let index = 0;

      let lerArquivo = (file) => {

        var reader = new FileReader();
        reader.onload = (e) => {
          var doc = espec.documentos.find(d => d.id === idDoc)          
          /*if (tipoDoc === 'frente') {            
            doc = espec.documentos.length > 0 ? espec.documentos[0] : null
          } else if (tipoDoc === 'verso') {       
            doc = espec.documentos.length > 1 ? espec.documentos[1] : null
          }else {
            doc = espec.documentos.length > 2 ? espec.documentos[2] : null
          }

          if (doc === null) {
            var minId = espec.documentos.reduce((min, doc) => (doc.id < min ? doc.id : min), 0)
            minId -= 1;
            doc = {
              "id": minId,
              "idTipo": null
            }
            espec.documentos.push(doc)
          }*/
          doc.nome = file.name
          doc.conteudo = e.target.result
          
          let indiceDoc = espec.documentos.indexOf(doc);          
          this.$set(espec.documentos, indiceDoc, doc)
        };
        reader.readAsDataURL(file)
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      };

      for (index = 0; index < event.target.files.length; index++) {
        var file = event.target.files[index];
        lerArquivo(file)
      }
    },
    alterarTipo(idTipo) {
      let tipo = this.tiposEspecializacao.find(t => t.id === idTipo)
      this.espec.dscTipo = tipo ? tipo.descricao : ''

      this.espec.documentos = [
        {id:-1, idTipo:null, parte: 'frente', titulo: 'Informe o documento ( Frente )'},
        {id:-2, idTipo:null, parte: 'verso', titulo: 'Informe o documento ( Verso )'}
      ]

      if (idTipo === 4) {
       this.espec.documentos.push({id:-3, idTipo:null, parte: 'rqe', titulo: 'Informe o comprovante RQE'}) 
      }

    },
    abrirArquivo(idDoc) {

      //var doc = this.espec.documentos.find(d => d.id === idDoc);
      //funcao.methods.downloadData(doc.arquivo, doc.nome);
      
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("abrirArquivo");

      AgendaService.download(idDoc)
        .then(resposta => {
          let ret = resposta.data                       
          if (ret.status === 'SUCCESS') {
            funcao.methods.download(ret.obj.conteudo, ret.obj.nome);
          } else {
            toast(data.mensagens[0].descricao, 'Falha', 'danger')
          }
          desativarShow("abrirArquivo");
        }).catch(error => {
          desativarShow("abrirArquivo");
          toast(error, "Falha", "danger")
        })
        
    },
    removerDocumento(idDoc) {
      var doc = this.espec.documentos.find(d => d.id === idDoc)
      var minId = this.espec.documentos.reduce((min, doc) => (doc.id < min ? doc.id : min), 0)
      minId -= 1
      doc.id = minId
      doc.nome = null
      doc.conteudo = null
      let indiceDoc = this.espec.documentos.indexOf(doc);        
      this.$set(this.espec.documentos, indiceDoc, doc)
    }
  },
  computed: {
    labelDataObtecao() {      
      var s = this.espec.idSituacao ? this.situacoesEspecializacao.find(s => s.id == this.espec.idSituacao) : null;
      return !s || s.identificador == 'CCA' ? 'Data de obtenção' : 'Previsão de obtenção'
    },
  }
}

function dateToStr(date, format) {
  if (date !== undefined && date !== null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
  return "";
}

</script>
<style></style>