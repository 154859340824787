<template>
  <div>    
    <base-input :label="campo.titulo" class="mb-0">
      <v-select id="input-especialidade" v-model="campo.valorId" :options="listaEspecialidade" :disabled="leitura"
        :reduce="especialidade => especialidade.id" label="descricao" placeholder="Informe a especialidade">
        <template #no-options>
          Nenhuma especialidade encontrada
        </template>
      </v-select>
    </base-input>
  </div>
</template>
  
<script>
import appUtils from '@/composables/utils';
import AgendaService from '../../service/AgendaService';

export default {
  props: ['leitura', 'campo'],
  components: {
    
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      id: null,     
      listaEspecialidade: [],      
    }
  },
  computed: {

  },
  mounted() {
    this.inicializar()
  },
  methods: {
    inicializar() {      
      this.buscarEspecialidades()      
    },    
    buscarEspecialidades() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarEspecialidades");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa
      }

      AgendaService.listaEspecialidade(dadosConsulta)
        .then(resposta => {
          this.listaEspecialidade = resposta.data.obj
          desativarShow("buscarEspecialidades");
        }).catch(error => {
          desativarShow("buscarEspecialidades");
        })

    },        
  },
  computed: {
    
  }
}

</script>
<style></style>