import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from "../service/auth.module";

Vue.use(Vuex)

const user = JSON.parse(localStorage.getItem('dadosUsuario'));
const empresa = JSON.parse(localStorage.getItem('dadosEmpresa'));
const initialState = user
  ? { status: { loggedIn: true }, user }
    : { status: { loggedIn: false }, user: null };

const initialEmpresa = empresa  ? { nome: '', logo: null } : { empresa };

//export default new Vuex.Store({
const store = {
    /*state: {
        empresa: initialEmpresa,
        auth: initialState,
        events: [],
        refCount: 0,
        isLoading: false
    },
    getters: {
        EVENTS: state => state.events,
        hasAccess: (state) => (menu) => {
            return state.auth.user && state.auth.user.grupo.menus.filter(m => m.titulo === menu).length > 0;
        },        
        hasPermission: (state) => (menu, permissao) => {
            return state.auth.user && state.auth.user.grupo.permissoes.filter(p => p.menu === menu && p.permissao === permissao).length > 0;
        },
        userName (state) {
            return state.auth.user ? state.auth.user.nome : ''
        },
        logoEmpresa(state) {            
            return state.empresa ? state.empresa.logo : null
        },
        empresa(state) {            
            return state.empresa ? state.empresa : null
        }
    },
    mutations: {
        ADD_EVENT: (state, event) => {
            console.log(event)
            state.events.push(event)
        },
        UPDATE_EVENT: (state, event) => {
            let index = state.events.findIndex(_event => _event.id == id) 
            
            state.events[index].title = event.title;
            state.events[index].start = event.start;
            state.events[index].end = event.end;

        },
        loading(state, isLoading) {
            console.log({isLoading})
            if (isLoading) {
                state.refCount++;
                state.isLoading = true;
            } else if (state.refCount > 0) {
                state.refCount--;
                state.isLoading = (state.refCount > 0);
            }
        },        
        loadEmpresaSuccess(state, empresa) {                        
            state.empresa = empresa;
        },
        loginSuccess(state, user) {
            state.auth.status.loggedIn = true;
            state.auth.user = user;            
            state.empresa = user.grupo.empresa;
        },
        logout(state) {
            state.auth.status.loggedIn = false;
            state.auth.user = null;
            state.empresa = null;
        }          
    },
    actions: {
        autoRefresh (context, credentials) {
            const { state, commit, dispatch } = context
            const { accessToken } = state
            const { exp } = jwt_decode(accessToken)
            const now = Date.now() / 1000 // exp is represented in seconds since epoch
            let timeUntilRefresh = exp - now
            timeUntilRefresh -= (15 * 60) // Refresh 15 minutes before it expires
            const refreshTask = setTimeout(() => dispatch('refreshTokens', credentials), timeUntilRefresh * 1000)
            commit('refreshTask', refreshTask) // In case you want to cancel this task on logout
        },
        REFRESH_TOKEN: () => {
            return new Promise((resolve, reject) => {
                let dados = {
                    "refresh_token": localStorage.getItem('refresh_token'),
                    "grant_type": "refresh_token"
                }
                axios.post(BASE_CONSTANTS.urlBase + 'oauth/token', dados, {
                    headers: {
                        'Content-Type':'application/json',
                        'Authorization':'Basic cmNiYXNza2V5OnNlY3JldA=='
                    }
                })
                .then((response) => {
                    this.msg = 'Carregando informações...'
                        this.token = JSON.stringify(response.data.access_token)
                        let decoded = jwt_decode(response.data.access_token);

                        if (this.token !== null || this.token !== undefined || this.token !== '' || this.token !== 'null') {
                        localStorage.setItem('authorization', JSON.stringify(response.data.access_token))
                        localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token))
                        }
                        resolve(response)
                }).catch(error => {
                    reject(error);
                });
                });
        },                    
        loadEmpresa({ commit }, empresa) {        
            commit('loadEmpresaSuccess', empresa);
        },
        login({ commit }, user) {        
            commit('loginSuccess', user);            
        },
        logout({ commit }) {        
            commit('logout');
        }
    },*/      
}


export default new Vuex.Store({
    modules: {
    store,
    auth
  }
});


