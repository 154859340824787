<template>
  <div class="credenciamento">

    <formulario
    v-if="formulario" :form="formulario" @executou="(r) => { if (r.status === 'success') this.enviarDados() }"
      @alterouCnpj="(r) => { this.alterouCnpj(r) }" :showEnviando="show" />

  </div>
</template>
<script>
import getUF from '@/composables/getUF'
import appUtils from '@/composables/utils'
import Card from 'src/components/Cards/Card.vue'
import { VMoney } from 'v-money'
import AgendaService from '../../../service/AgendaService'
import Formulario from '../../comuns/Formulario.vue'

export default {
  components: {
    Card,
    Formulario
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      URL_PAGE: this.$route.query.page,
      formulario: null,
      optionsUF: getUF(),
      mascara: '(##)#####-####',
      empresa: '',
      tipo: 'PF',
      idEmpresa: 0,
      idEmpresaPJ: null,
      destino: "Agenda",
      id: null,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      dismissSecs: 5,
      dismissCountDown: 0,
      headerFalha: '',
      mensagemFalha: ''
    }
  },
  directives: { money: VMoney },
  async mounted() {
    try {

      this.empresa = this.$route.params.empresa
      const tipo = this.$route.query.tp
      const empresaPJ = this.$route.params.empresaPJ;
      const destino = this.$route.query.dest
      const id = this.$route.query.ch

      if (tipo) this.tipo = atob(tipo)
      if (destino) this.destino = atob(destino)
      if (empresaPJ) this.idEmpresaPJ = empresaPJ
      if (id) this.id = atob(id)

      const { toast, ativarShow, desativarShow } = appUtils(this)

      if (this.empresa) {

        this.optionsUF = getUF()

        let dadosConsulta = '{ "identificador": "' + this.empresa + '"}'

        ativarShow("buscarEmpresa")

        await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
          .then((resposta) => {

            let retorno = resposta.data;

            if (retorno.status === "SUCCESS") {
              this.idEmpresa = resposta.data.obj.id
              this.$store.dispatch('auth/loadEmpresa', retorno.obj);
            } else {
              if (retorno.mensagens.length > 0) {
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }

            desativarShow("buscarEmpresa");

          }).catch((error) => {
            desativarShow("buscarEmpresa");
            toast(error, 'Falha', 'danger')
          });

        if (this.idEmpresa > 0) {

          if (this.loggedIn()) {
            ativarShow("buscarFormularioCredenciamento");

            let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

            let dadosConsulta = {
              "idUsuario": dadosUsuario.idPessoa,
              "idProfissional": dadosUsuario.idPessoa,
              "idEmpresa": this.idEmpresa,
              "idEmpresaPJ": this.idEmpresaPJ,
              "tipo": this.tipo,
              "id": this.id
            }

            AgendaService.buscarFormularioCredenciamento(dadosConsulta)
              .then((resposta) => {
                let retorno = resposta.data;
                if (retorno.status === "SUCCESS") {
                  this.formulario = retorno.obj;      
                  this.formulario.campos.filter((c) => { return c.tipo == 'CNPJ' })
                    .forEach(c => { 
                      this.alterouCnpj(
                        { 'campo': c.nome, 'cnpj': c.valor }
                      )
                    })
                } else if (retorno.mensagens.length > 0) {
                  toast(retorno.mensagens[0].descricao,'Falha','danger')                 
                }
                desativarShow("buscarFormularioCredenciamento");
              }).catch((error) => {
                desativarShow("buscarFormularioCredenciamento");
                toast(error, 'Falha', 'danger')
              });
          } else {
            this.$router.push({
              name: 'CriarConta', params: { empresa: this.empresa },
              query: { redirect: '/' + this.empresa + '/credenciamento' }
            });
          }
        }

      }

    } catch (e) {
      alert(e)
    }
  },
  methods: {
    
    async alterouCnpj(r) {
            
      var campoCnpj = this.formulario.campos.find(c => c.nome === r.campo)
      
      if (campoCnpj.conf != null && campoCnpj.conf.validarCredenciamento) {
        var cred = null
        var cnpj = r.cnpj ? r.cnpj.replace(/[^0-9]/ig, '') : '';
        let tamanhoString = cnpj.length
        if (tamanhoString == 14) {
          cred = await this.buscarCredenciamento(cnpj)
        }

        var comCredenciamento = cred != null && cred.situacao == 'CCD'
        if (campoCnpj.conf.camposRemoverCredenciamento != null) {
          campoCnpj.conf.camposRemoverCredenciamento.forEach((cr) => {
            var campo = this.formulario.campos.find(c => c.nome === cr)
            if (campo) {
              campo.visivel = !comCredenciamento
            }
          });
        }

        if (campoCnpj.conf.camposAdicionarCredenciamento != null) {
          campoCnpj.conf.camposAdicionarCredenciamento.forEach((cr) => {
            var campo = this.formulario.campos.find(c => c.nome === cr)
            if (campo) {
              campo.visivel = comCredenciamento
            }
          });
        }
      }      
    },
    async buscarCredenciamento(cnpj) {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarCredenciamento")

      let dadosConsulta = {
        "idEmpresa": this.idEmpresa,
        "idsEliminar": [this.id],
        "cnpj": cnpj
      }

      return await AgendaService.listaCredenciamento(dadosConsulta)
        .then((resposta) => {
          desativarShow("buscarCredenciamento")
          var resp = resposta.data                    
          return resp.status == 'SUCCESS' && resp.obj.length > 0 ? resp.obj[0] : null
        })
        .catch((error) => {
          desativarShow("buscarCredenciamento")
        });
    },
    enviarDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"))

      this.show = true
      
      let credenciamento = {
        "idUsuario": dadosUsuario.idPessoa,
        "idProfissional": dadosUsuario.idPessoa,
        "idEmpresa": this.idEmpresa,
        "formulario": this.formulario,
        "tipo": this.tipo
      }

      AgendaService.enviarDadosCredenciamento(credenciamento)
        .then((resposta) => {

          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            if (retorno.obj.erroValidacao == 'S') {
              let time = 1;
              retorno.obj.mensagens.forEach((msg) => {
                setTimeout(function () {                  
                  toast(msg.descricao, msg.titulo, msg.tipo)
                }, time);                
                time= time + 2000                
              });
              
              this.formulario = retorno.obj.formulario
            } else {
              this.$router.push({ name: 'PosEnviarCredenciamento', params: { empresa: this.empresa } });  
            }            
            
          } else {            
            if (retorno.mensagens.length > 0) {
              let time = 1;
              retorno.mensagens.forEach((msg) => {                
                setTimeout(function () {
                  toast(msg.descricao, msg.titulo, msg.tipo)  
                }, time);                
                time= time + 3000
              });
            }
          }
          this.show = false
        }).catch((error) => { 
          this.show = false          
          toast(error, 'Falha', 'danger')
        });

    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    }
  },
  computed: {

  }

}

const validar = cpf => checkAll(prepare(cpf))

const notDig = i => !['.', '-', ' '].includes(i)
const prepare = cpf => cpf.trim().split('').filter(notDig).map(Number)
const is11Len = cpf => cpf.length === 11
const notAllEquals = cpf => !cpf.every(i => cpf[0] === i)
const onlyNum = cpf => cpf.every(i => !isNaN(i))

const calcDig = limit => (a, i, idx) => a + i * ((limit + 1) - idx)
const somaDig = (cpf, limit) => cpf.slice(0, limit).reduce(calcDig(limit), 0)
const resto11 = somaDig => 11 - (somaDig % 11)
const zero1011 = resto11 => [10, 11].includes(resto11) ? 0 : resto11

const getDV = (cpf, limit) => zero1011(resto11(somaDig(cpf, limit)))
const verDig = pos => cpf => getDV(cpf, pos) === cpf[pos]

const checks = [is11Len, notAllEquals, onlyNum, verDig(9), verDig(10)]
const checkAll = cpf => checks.map(f => f(cpf)).every(r => !!r)

</script>
<style lang="scss">

.credenciamento{
  p.sem-margem{
      margin-bottom: 0;
  }

  
}

  /*.orientacoes-credenciamento{
    p{
      font-size: 0.7rem;
    }
  }*/

</style>