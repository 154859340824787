<template>
    <div>           

      <b-modal ref="modal-vaga" id="modal-vaga" title="Detalhes da Vaga" size="xl">
        
        <dados-vaga @showOverlay="(status) => this.$emit('showOverlay', status)" @executou="(r) => { fecharVaga(r) }" :idAgenda="idAgenda"></dados-vaga>  

        <template #modal-footer="{ cancel }">          
          <b-button size="md" variant="danger" class="btn-fill" @click="cancel()">
            Fechar
          </b-button>          
        </template>

      </b-modal>
    
  <card>

     <template slot="header">
        <h4 class="card-title">Mural de vagas</h4>
    </template>

    <b-container fluid class="mural-vaga mb-4"> 

      <b-row v-if="dadosFiltro">

        <b-col class="col-12 col-lg-2">
          <b-form-datepicker 
              v-model="dtInicio" 
              size="sm"                       
              placeholder="Data do plantão"
              today-button
              reset-button                    
              close-button               
              today-button-variant="primary"
              reset-button-variant="danger"
              close-button-variant="success"
              label-no-date-selected="Nenhuma data selecionada"
              label-today-button="Hoje"
              label-reset-button="Limpar"
              label-close-button="Fechar"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }">
          </b-form-datepicker>
        </b-col>
        <b-col class="col-12 col-lg-2 filtro">
          <b-form-datepicker 
              v-model="dtFim" 
              size="sm"                       
              placeholder="até"
              today-button
              reset-button                    
              close-button               
              today-button-variant="primary"
              reset-button-variant="danger"
              close-button-variant="success"
              label-no-date-selected="Nenhuma data selecionada"
              label-today-button="Hoje"
              label-reset-button="Limpar"
              label-close-button="Fechar"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }">
          </b-form-datepicker>
        
        </b-col>

        <b-col class="col-12 col-lg-2 filtro">
          <b-input-group>
                    <b-form-input 
                      type="time"
                      label="Horário"
                      placeholder="hh:mm"
                      v-model="horaInicio"
                      size="sm">
                    </b-form-input>
                    <b-input-group-text class="mb-2">até</b-input-group-text>
                    <b-form-input 
                      type="time"
                      label="até"
                      placeholder="hh:mm"
                      v-model="horaFim"
                      size="sm">
                    </b-form-input>               
          </b-input-group>
        </b-col>

        <b-col class="col-12 col-lg-2 filtro">           
          <v-select multiple 
              v-model="municipiosSelecioandos"
              :on-change="filtroAlterado"
              @input="filtroAlterado"
              :options="dadosFiltro.municipios"
              label="descricao"
              :reduce="m => m.codigo"
              ref="select"
              placeholder="Município">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
          </v-select>
        </b-col>
        <b-col class="col-12 col-lg-2 filtro"> 
          <v-select multiple
              v-model="locaisSelecionados"
              :on-change="filtroAlterado"
              @input="filtroAlterado"
              :options="dadosFiltro.locais"
              label="descricao"
              :reduce="l => l.id"
              ref="select"
              placeholder="Local">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
          </v-select>
        </b-col>
        <b-col class="col-12 col-lg-2 filtro">
          <v-select multiple
              v-model="tiposSelecioandos"
              :on-change="filtroAlterado"
              @input="filtroAlterado"
              :options="dadosFiltro.tipos"
              label="descricao"
              :reduce="t => t.id"
              ref="select"
              placeholder="Tipo de serviço">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
          </v-select>
        </b-col>

        <b-col class="col-12 col-lg-1 filtro">
          <b-button size="sm" variant="primary" class="btn btn-fill btn-primary btn-block w-100" @click="buscarVagas" >
              <b-icon icon="filter" aria-hidden="true" class="mr-1"></b-icon>Buscar</b-button>              
        </b-col>

      </b-row>
      
      <b-row class="mural-vaga pt-1">

        <div v-for="(vaga,index) in vagas" :key="index" class="box-vaga col-sm-4 col-lg-2">
        <b-card class="shadow">      
          <b-card-text>

            <p class="text-truncate linha-info text-secondary"><span class="font-weight-bold text-dark titulo-info">Município: </span>{{ vaga.cidade + ' - ' + vaga.uf }}</p>             
            <p class="text-truncate linha-info text-secondary"><span class="font-weight-bold text-dark titulo-info">Local: </span> {{vaga.dscEstabelecimento}} </p>             
            <p class="text-truncate linha-info text-secondary"><span class="font-weight-bold text-dark titulo-info">Setor: </span> {{vaga.dscLocal}} </p>
            <p class="text-truncate linha-info text-secondary"><span class="font-weight-bold text-dark titulo-info">Tipo: </span> {{ vaga.dscTipo }} </p> 
            <p class="text-truncate linha-info text-secondary" ><span class="font-weight-bold text-dark titulo-info">Data: </span> {{ strDateFormat(vaga.dtAgenda, 'dd/MM/yyyy') }}</p> 
            
            <p class="text-truncate linha-info text-secondary"><span class="font-weight-bold text-dark titulo-info">Horário: </span> {{vaga.horarioExtenso}}
              <span v-if="vaga.quantidadeEscala"> <span class="ml-2 font-weight-bold text-dark titulo-info">Quantidade: </span> {{vaga.quantidadeEscala}}</span>
            </p>                        

            <p class="text-truncate linha-info text-secondary"><span class="font-weight-bold titulo text-dark titulo-info">Valor: </span> <span class="text-success"> R$ {{vaga.valor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL', maximumFractionDigits: 2})}} </span></p>
          </b-card-text>
                    
          <template #footer>                        

            <b-row class="w-100">

            <b-col class="col-6  text-right pr-1">
              <b-link @click="abrirVaga(vaga.idAgenda)" href="#" class="text-primary link-abrir" >
                  <b-icon icon="eye" aria-hidden="true"></b-icon>
                  <span class="ml-1">Detalhes</span>
              </b-link>
            </b-col>
            <b-col class="col-6 text-left pl-1">
              <b-link @click="copy(gerarLinkVaga(vaga.idAgenda, vaga.identificadorEmpresa))" href="#" class="text-secondary link-copiar" >
                  <b-icon icon="link" aria-hidden="true"></b-icon>
                  <span class="ml-1">Copiar</span>
              </b-link>
            </b-col>
          </b-row>

          </template>
        </b-card>           
        </div>      

      </b-row>
          
    </b-container>    

   </card>    
  </div>
  
</template>

<script>
  import appUtils from '@/composables/utils'
import Card from 'src/components/Cards/Card.vue'
import AgendaService from '../service/AgendaService'
import DadosVaga from './comuns/DadosVaga'
  
  export default {
    components: {
      Card,
      DadosVaga,
    },
    data () {
      return {
        http: null,
        show: false,
        showFrom: "",
        filaShow: [],
        idLocal: null,
        idTipo: null,
        dtInicio: null,
        dtFim: null,        
        horaInicio:null,
        horaFim: null,
        uf: null,
        idCidade: null,
        idEmpresa: null,
        idEspecialidade: null,
        empresa: '',
        vagas: [],
        dtInicio: null,
        dtFim: null,
        fixo: 'todos',
        dadosFiltro: null,
        municipiosSelecioandos: null,
        locaisSelecionados: null,
        tiposSelecioandos: null,
        idAgenda: 0,
      }
    },
  async mounted() {

    const { toast, ativarShow, desativarShow } = appUtils(this)

    try {          

      this.empresa = this.$route.params.empresa;
            
      let dadosConsulta = {         
        "identificador": "'" + this.empresa + "'"
      }

        if(this.empresa){
          ativarShow("buscarEmpresa");

          await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
            .then((resposta) => {
              let retorno = resposta.data;
              if (retorno.status === "SUCCESS") {            
                this.idEmpresa = retorno.obj.id        
                this.$store.dispatch('auth/loadEmpresa', retorno.obj);                
              } else {                        
                if (retorno.mensagens.length > 0) {              
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }
              desativarShow("buscarEmpresa");
          }).catch((error) => {          
              desativarShow("buscarEmpresa");
              toast(error, 'Falha', 'danger')
          });
      }

      this.dtInicio = dateToStr(new Date(), "yyyy-MM-dd")
      this.buscarDadosFiltroVagas()
      this.buscarVagas()
        
      }catch(e){
        toast(e, 'Falha', 'danger')
      }           
    },
  methods: {
    buscarVagas(){

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarVagas");

      const dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        idEmpresa: this.idEmpresa,
        idUsuario: dadosUsuario.idPessoa,
        "idsLocal": this.locaisSelecionados,
        "idsTipo": this.tiposSelecioandos,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "municipios": this.municipiosSelecioandos,
        "horaInicio": this.horaInicio,
        "horaFim": this.horaFim,
        "fixo": this.fixo === 'fixo' ? 'S' : this.fixo === 'naoFixo' ? 'N' : '',
      }      

      AgendaService.buscarVagasPlantao(dadosConsulta)
        .then((resposta) => {         
        this.vagas = resposta.data.obj        
        this.totalRows = this.items.length                    
        desativarShow("buscarVagas");
      })
      .catch((err) => {          
        desativarShow("buscarVagas");
      });

    },      
    buscarDadosFiltroVagas() {      

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarDadosFiltroVagas");

      let dadosConsulta = {
        
      }

      AgendaService.buscarDadosFiltroVagas(dadosConsulta)
        .then((resposta) => {  
        this.dadosFiltro = resposta.data.obj                            
        desativarShow("buscarDadosFiltroVagas");
      })
      .catch((err) => {          
        desativarShow("buscarDadosFiltroVagas");
      });
      },
      criar() {         

        const { toast, ativarShow, desativarShow } = appUtils(this)

        ativarShow("criarConta");

        UsuarioService.criarConta(this.http, this.profissional)
          .then((resposta) => {

            let retorno = resposta.data;

            if (retorno.status === "SUCCESS") {            

              toast(retorno.mensagens[0].descricao, "Sucesso", "success")                            

              toast('Logando no sistema', 'Autenticando', 'info')

              let user = {
                "username": this.profissional.email,
                "password": this.profissional.senha,
              }
          
              this.$store.dispatch('auth/login', user)
                .then((user) => {                      
                      var v = this
                      setTimeout(function () {                
                        v.$router.push({ name: 'Credenciamento', params: { empresa: v.empresa } });                  
                      }, 2000);                    
                  },
                  error => {      
                    toast('Falha ao autenticar. Tente realizar o login novamente.', 'Falha', 'danger')              
                  }
              );          

            } else {                        
              if (retorno.mensagens.length > 0) {              
                toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
              }
            }

            desativarShow("criarConta");

        }).catch((error) => {          
            desativarShow("criarConta");
            toast(error, 'Falha', 'danger')
        });        
    },         
    abrirVaga(id) {                        
      this.idAgenda = id
      this.$bvModal.show("modal-vaga")
    },
    fecharVaga(v){
      this.$bvModal.hide("modal-vaga")
    },
      login() {
        this.$router.push({ name: 'Acesso', query: { redirect: '/'+this.empresa+'/credenciamento' } });
      },
      strDateFormat(dataStr, format) {
        let data = new Date(dataStr + " (UTC-3)");
        return dateToStr(data, format);
    },
    gerarLinkVaga(id, emp) {
      var url = window.location.origin + "/#/" + emp + '/vaga/' + id      
      return url
    },
    async copy(s) {
      const { toast} = appUtils(this)
      await navigator.clipboard.writeText(s);      
      toast("URL copiada", 'Cópia', 'success')
    },
    filtroAlterado() {
      
    }
      
  },           
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
  
}

</script>

<style lang="scss">

.mural-vaga {

  /*.filtro{
    font-size: 0.7rem; 
  }*/

}

.box-vaga{
  
  .linha-info{
    font-size: 9px;
    padding-bottom: 0;
    margin-bottom: 3px;
  }

  .titulo-info{
    font-size: 11px;
  }

  .link-abrir{
    font-size: 12px;
  }

  .link-copiar{
    font-size: 12px;
  }

}

@media (max-width: 768px) {

.box-vaga{
  font-size: 0.5rem;
}

.mural-vaga .filtro{
  padding-top: 5px;
}  

}


</style>