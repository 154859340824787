<template>
  <b-container fluid class="p-4">
    <b-card class="shadow rounded">
      <div class="d-flex flex-column mb-4">
        <h3 class="text-primary font-weight-bold">
          <i class="fas fa-calendar-alt mr-2 fa-bounce"></i>Plantão - Detalhes e Ações
        </h3>
        <small class="text-muted">Gerencie seu plantão: horários, unidade, setor e ações rápidas</small>
      </div>

      <div class="mb-3">
        <b-badge :variant="badgeSetor(plantao.setor)">
          <i class="fas fa-hospital mr-1 fa-shake"></i> {{ plantao.setor || 'Setor não informado' }}
        </b-badge>
        <b-badge v-if="status === 'checkin'" variant="success" class="ml-2">
          <i class="fas fa-check-circle"></i> Check-in realizado
        </b-badge>
        <b-badge v-if="status === 'checkout'" variant="danger" class="ml-2">
          <i class="fas fa-times-circle"></i> Check-out realizado
        </b-badge>
      </div>

      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label="Data">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-calendar-alt fa-fade"></i></b-input-group-text>
                <b-form-input type="date" v-model="plantao.data" readonly></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Horário">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-clock fa-beat"></i></b-input-group-text>
                <b-form-input v-model="plantao.horario" readonly></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Duração (horas)">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-hourglass-half"></i></b-input-group-text>
                <b-form-input v-model="plantao.duracao" readonly></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <b-form-group label="Unidade">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-hospital-alt"></i></b-input-group-text>
                <b-form-input v-model="plantao.unidade" readonly></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Setor">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-layer-group"></i></b-input-group-text>
                <b-form-input v-model="plantao.setor" readonly></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Tipo de Serviço">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-stethoscope"></i></b-input-group-text>
                <b-form-input v-model="plantao.tipoServico" readonly></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <b-form-group label="Valor a Receber">
              <b-input-group prepend>
                <b-input-group-text><i class="fas fa-dollar-sign"></i></b-input-group-text>
                <b-form-input type="number" prefix="R$" v-model="plantao.valor" readonly step="0.01"></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-button variant="link" class="botao-link mr-2" :disabled="status === 'checkin' || loading === 'checkin'" @click="checkIn" v-b-tooltip.hover title="Registrar início do plantão">
              <i :class="['fas', loading === 'checkin' ? 'fa-spinner fa-spin' : 'fa-sign-in-alt', 'mr-1']"></i>
              {{ status === 'checkin' ? 'Check-in realizado' : 'Check-in' }}
            </b-button>
            <b-button variant="link" class="botao-link mr-2 text-danger" :disabled="status === 'checkout' || loading === 'checkout'" @click="checkOut" v-b-tooltip.hover title="Registrar encerramento do plantão">
              <i :class="['fas', loading === 'checkout' ? 'fa-spinner fa-spin' : 'fa-sign-out-alt', 'mr-1']"></i>
              {{ status === 'checkout' ? 'Check-out realizado' : 'Check-out' }}
            </b-button>
            <b-button variant="link" class="botao-link text-warning" :disabled="loading === 'troca'" @click="trocar" v-b-tooltip.hover title="Solicitar troca deste plantão">
              <i :class="['fas', loading === 'troca' ? 'fa-spinner fa-spin' : 'fa-exchange-alt', 'mr-1']"></i>
              Troca
            </b-button>
          </b-col>
        </b-row>
      </b-form>

      <b-toast v-if="toastMessage" :variant="toastVariant" solid auto-hide-delay="3000" @hidden="toastMessage = ''">
        {{ toastMessage }}
      </b-toast>
    </b-card>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      plantao: {
        data: '2025-03-26',
        horario: '08:00 às 14:00',
        duracao: '6h',
        unidade: 'Hospital da baleia',
        setor: 'CTI1',
        tipoServico: 'Dia',
        valor: '1250.00'
      },
      status: '',
      toastMessage: '',
      toastVariant: 'info',
      loading: '' // 'checkin', 'checkout', 'troca'
    };
  },
  methods: {
    badgeSetor(setor) {
      const cores = {
        'Emergência': 'info',
        'Clínica Geral': 'success',
        'Pediatria': 'warning'
      };
      return cores[setor] || 'secondary';
    },
    async checkIn() {
      this.loading = 'checkin';
      await this.delay();
      this.status = 'checkin';
      this.toastMessage = '✅ Check-in realizado com sucesso!';
      this.toastVariant = 'success';
      this.loading = '';
    },
    async checkOut() {
      this.loading = 'checkout';
      await this.delay();
      this.status = 'checkout';
      this.toastMessage = '✅ Check-out realizado com sucesso!';
      this.toastVariant = 'danger';
      this.loading = '';
    },
    async trocar() {
      this.loading = 'troca';
      await this.delay();
      this.toastMessage = '🔄 Troca solicitada para este plantão';
      this.toastVariant = 'warning';
      this.loading = '';
    },
    delay(ms = 1000) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  }
};
</script>

<style scoped>

.botao-link {
  text-decoration: none !important;
  border: none !important;
  box-shadow: none !important;
  padding: 0.25rem 0.5rem;
}

.botao-link:hover {
  text-decoration: none !important;
}


</style>
