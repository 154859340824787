<template>
  <div class="sidebar-container">

    <div class="sidebar-columns">
      <div class="dropdown-container" v-if="hasAccess('AGENDA')">
        <div class="dropdown ">
          <i class="bi bi-calendar-week"></i>
          <div class="dropdown-content">
            <p> Agenda </p>            
            <a v-if="hasAccess('AGENDA')" href="#/admin/agenda">Agenda</a>            
            <a v-if="hasAccess('MANUTENCAO_AGENDA')" href="#/admin/manutencao-agenda">Manutenção</a>            
          </div>
        </div>
      </div>

      <div class="dropdown-container" v-if="hasAccess('RELATORIO_FINANCEIRO') || hasAccess('FECHAMENTOS') || hasAccess('PAGAMENTOS') || hasAccess('LISTA_APROVACAO')">
        <div class="dropdown ">
          <i class="bi bi-cash-coin"></i>
          <div class="dropdown-content">
            <p class="titulo-menu"> Financeiro </p>
            <a v-if="hasAccess('RELATORIO_FINANCEIRO')" href="#/admin/relatorio-financeiro">Relatório</a>            
            <a v-if="hasAccess('FECHAMENTOS')" href="#/admin/fechamentos">Fechamento</a>
            <a v-if="hasAccess('PAGAMENTOS')" href="#/prof/pagamento">Pagamentos</a>
            <a v-if="hasAccess('LISTA_APROVACAO')" href="#/admin/list-aprovacao">Aprovações</a>
          </div>
        </div>
      </div>            

      <div class="dropdown-container" v-if="hasMenu('ADM')">
        <div class="dropdown">
          <i class="bi bi-person-plus"></i>
          <div class="dropdown-content">
            <p> Cadastros </p>
            <a v-if="hasAccess('CAD_LOCAL')" href="#/admin/cad/local">Locais</a>
            <a v-if="hasAccess('CAD_USUARIO')" href="#/admin/cad/usuario">Usuários</a>
            <a v-if="hasAccess('CAD_PROFISSIONAL')" href="#/admin/cad/profissional">Profissionais</a>
            <a v-if="hasAccess('CAD_TIPO')" href="#/admin/cad/tipo">Tipos de serviço</a>
            <a v-if="hasAccess('CAD_GRUPO_USUARIO')" href="#/admin/cad/grupo-usuario">Grupos de usuário</a>
            <a v-if="hasAccess('CAD_EMPRESA')" href="#/admin/cad/empresa">Empresa</a>
            <a v-if="hasAccess('CAD_EMPRESA_PJ')" href="#/admin/cad/empresa-pj">Empresa PJ</a>
            <a v-if="hasAccess('CAD_SITUACAO')" href="#/admin/cad/situacao">Situação</a>
            <a v-if="hasAccess('CAD_ESCALA')" href="#/admin/escala">Escala</a>
            <a v-if="hasAccess('CAD_CLASSIFICACAO')" href="#/admin/cad/classificacao">Classificação</a>
          </div>
        </div>
      </div>

      <div class="dropdown-container" v-if="hasAccess('LISTA_CREDENCIAMENTO') || hasAccess('CREDENCIAMENTO') || hasAccess('LISTA_CREDENCIAMENTO_PJ')">
        <div class="dropdown">
          <i class="bi bi-file-earmark-text"></i> 
          <div class="dropdown-content">
            <p> Credenciamento </p>
            <a v-if="hasAccess('LISTA_CREDENCIAMENTO')" href="#/admin/list-credenciamento">Lista de credenciamentos</a>
            <a v-if="hasAccess('CREDENCIAMENTO')" href="#/admin/credenciamentoi">Credenciamento</a>            
            <a v-if="hasAccess('LISTA_CREDENCIAMENTO_PJ')" href="#/admin/list-credenciamento-pj">Empresas</a>
            <a v-if="hasAccess('LISTA_CREDENCIAMENTO')" href="#/admin/dash-credenciamento">Dashboard</a>
            <a v-if="hasAccess('RELATORIO')" href="#/admin/rel-credenciamento">Relatórios</a>
          </div>
        </div>
      </div>

      <div class="dropdown-container" v-if="hasAccess('LISTA_CANDIDATO') || hasAccess('MURAL_VAGA') || hasAccess('LISTA_CANDIDATURA')">
        <div class="dropdown">
          <i class="bi bi-person-workspace"></i>
          <div class="dropdown-content">
            <p> Vagas </p>
            <a v-if="hasAccess('LISTA_CANDIDATO')" href="#/admin/list-candidato">Aprovar candidato</a>
            <a v-if="hasAccess('MURAL_VAGA')" href="#/muralVaga">Mural de vagas</a>            
            <a v-if="hasAccess('LISTA_CANDIDATURA')" href="#/admin/list-candidatura">Candidaturas</a>
          </div>
        </div>
      </div>

      <div class="dropdown-container" v-if="hasAccess('DASHBOARD')">
        <div class="dropdown">
          <i class="bi bi-graph-up-arrow"></i>
          <div class="dropdown-content">
            <p> Dashboard </p>
            <a v-if="hasAccess('DASHBOARD')" href="#/admin/dashboard">Geral</a>            
          </div>
        </div>
      </div>

      <div class="dropdown-container" v-if="hasAccess('RELATORIO') || hasAccess('RELATORIO_GERAL')">
        <div class="dropdown">
          <i class="bi bi-file-earmark-bar-graph"></i>
          <div class="dropdown-content">
            <p> Relatórios </p>
            <a v-if="hasAccess('RELATORIO_GERAL')" href="#/admin/relatorio">Geral</a>                        
          </div>
        </div>
      </div>

      <router-link to="/admin/dashboard" v-if="false && hasAccess('DASHBOARD')">
        <i class="nc-icon nc-chart-pie-36"></i>
      </router-link>            

    </div>
  </div>
</template>

<script>
export default {
  methods: {
    hasAccess(menu) {
      return this.$store.getters['auth/hasAccess'](menu)
    },
    hasMenu(posicao) {        
        return this.$store.getters['auth/hasMenu'](posicao)
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
    isTela(v) {
      return this.$route.name === v
    }
  }
}
</script>

<style scoped>
.sidebar-container {
  position: fixed;
  left: 0;
  bottom: 0;
  top: 3.75em;
  background: #3f7bb7;
  width: 3em;
  height: 100vh;
  color: #fff;
  z-index: 999 !important;
}

.sidebar-columns {
  display: flex;
  flex-direction: column;
}

.sidebar-columns a {
  color: white;
  padding: 0.3em 0.6em;
  opacity: .86;
  border-radius: 4px;
}

.sidebar-columns i {
  font-size: 28px;
}

.sidebar-columns a:hover {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
}

.dropdown-container {
  display: flex;
  justify-content: center;
  width: 3em;
  line-height: 31px;
}

.dropdown-container:hover {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
}

.dropdown-container i {
  font-size: 28px;
  color: #fff;
  opacity: .86;
}

.dropbtn {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: none;
}

.dropdown {
  position: relative;
  margin: 0;
  padding: 0.5em 1em;
  display: flex;
  transition: 0.2s;
  z-index: 999 !important;
}

.dropdown-content {
  display: none;
  position: fixed;
  left: 3em;
  width: 15em;
  height: 100vh;
  top: 3.5em;
  background-color: #fff;
  /* background-color: #4781ff; */
  /* min-height: 100%; */
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999999 !important;
}

.dropdown-content p {
  width: 100%;
  color: grey;
  padding: 12px 16px 0 16px;
  font-weight: bold;
}

.dropdown-content a {
  color: #3f7bb7;
  font-weight: bold;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  left: 50px;
  border-bottom: 1px solid rgb(221, 220, 220);
}

.dropdown-content a:hover {
  background-color: #2f6aeb;
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;

}
</style>