<template>
  <div class="wrapper">
    <top-navbar class="desktop"></top-navbar>

    <!-- <side-bar class="desktop">
      <mobile-menu slot="content"></mobile-menu>

      <div class="dropdown-container" v-if="hasAccess('AGENDA')">
        <div class="dropdown ">
          <i class="nc-icon nc-notes dropbtn"></i>          
          <div class="dropdown-content">
            <p class="titulo-menu"> ESCALA </p>
            <a v-if="hasAccess('AGENDA')" href="#/admin/agenda">Agenda</a>            
            <a v-if="hasAccess('MANUTENCAO_AGENDA')" href="#/admin/manutencao-agenda">Manutenção</a>            
          </div>
        </div>
      </div>

      <div class="dropdown-container" v-if="hasAccess('RELATORIO_FINANCEIRO') || hasAccess('FECHAMENTOS')">
        <div class="dropdown ">
          <i class="nc-icon nc-paper-2 dropbtn"></i>          
          <div class="dropdown-content">
            <p class="titulo-menu"> FINANCEIRO </p>
            <a v-if="hasAccess('RELATORIO_FINANCEIRO')" href="#/admin/relatorio-financeiro">Relatório</a>            
            <a v-if="hasAccess('FECHAMENTOS')" href="#/admin/fechamentos">Fechamento</a>
          </div>
        </div>
      </div>      

      <div class="dropdown-container" v-if="hasMenu('ADM')">
        <div class="dropdown">
          <i class="nc-icon nc-grid-45"></i>
          <div class="dropdown-content">
            <p class="titulo-menu"> CADASTROS </p>
            <a v-if="hasAccess('CAD_LOCAL')" href="#/admin/cad/local">Locais</a>
            <a v-if="hasAccess('CAD_USUARIO')" href="#/admin/cad/usuario">Usuários</a>
            <a v-if="hasAccess('CAD_PROFISSIONAL')" href="#/admin/cad/profissional">Profissionais</a>
            <a v-if="hasAccess('CAD_TIPO')" href="#/admin/cad/tipo">Tipos de serviço</a>
            <a v-if="hasAccess('CAD_GRUPO_USUARIO')" href="#/admin/cad/grupo-usuario">Grupos de usuário</a>
            <a v-if="hasAccess('CAD_EMPRESA')" href="#/admin/cad/empresa">Empresa</a>
            <a v-if="hasAccess('CAD_SITUACAO')" href="#/admin/cad/situacao">Situação</a>
            <a v-if="hasAccess('CAD_SITUACAO')" href="#/admin/escala">Escala</a>
          </div>
        </div>
      </div>
      
      <sidebar-link to="/admin/dashboard" v-if="hasAccess('DASHBOARD')">
        <i class="nc-icon nc-chart-pie-36"></i>
      </sidebar-link>

      <sidebar-link to="/admin/credenciamento" v-if="hasAccess('CREDENCIAMENTO')">
        <i class="nc-icon nc-single-copy-04"></i>
      </sidebar-link>

      <sidebar-link to="/admin/list-credenciamento" v-if="hasAccess('LISTA_CREDENCIAMENTO')">
        <i class="nc-icon nc-single-copy-04"></i>
      </sidebar-link>

      <sidebar-link to="/admin/list-candidato" v-if="hasAccess('LISTA_CANDIDATO')">
        <i class="nc-icon nc-single-copy-04"></i>
      </sidebar-link>

      <sidebar-link to="/muralVaga" v-if="hasAccess('MURAL_VAGA') && !isTela('MuralVaga') && !isTela('MuralVagaGeral')">
        <i class="nc-icon nc-grid-45"></i>
      </sidebar-link>

      <sidebar-link to="/acesso" v-if="!loggedIn && !isTela('Acesso')">
        <i class="nc-icon nc-circle-09"></i>
      </sidebar-link>

    </side-bar> -->

    <DesktopSideBar class="desktop" v-if="loggedIn"/>

    <div class="main-panel" :style="loggedIn ? 'width: calc(100% - 3.5em)' : 'width: 100%;'" v-if="window>=990">
      <!-- <top-navbar></top-navbar> -->      
      <dashboard-content @click="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>

    <div class="main-panel-mobile" v-if="window<990">
      <top-navbar></top-navbar>
      <dashboard-content @click="toggleSidebar"> </dashboard-content>
      <content-footer></content-footer>
    </div>

  </div>
</template>
<style lang="scss">
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
</style>
<script>
import DashboardContent from "./Content.vue";
import ContentFooter from "./ContentFooter.vue";
import DesktopSideBar from './DesktopSideBar.vue';
import MobileMenu from "./MobileMenu.vue";
import TopNavbar from "./TopNavbar.vue";

export default {
  meta: [
    { name: "cache-control", content: "no-cache" },
    { name: "expires", content: "0" },
    { name: "pragma", content: "no-cache" },
  ],

  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
    DesktopSideBar,
  },
  mounted() {
    //limparCacheJS()

    /*this.token = localStorage.getItem("authorization");
    
    if (this.token === null || this.token === undefined || this.token === "" || this.token === "null"
    ) {
      this.$store.dispatch('logout')
      this.$router.push("/login");
    }*/

  },
  created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
  },
  data() {
    return {
      token: null,
      showFloatingMenu: false,
      window: false,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    hasAccess(menu) {
      return this.$store.getters['auth/hasAccess'](menu)
    },
    hasMenu(posicao) {        
        return this.$store.getters['auth/hasMenu'](posicao)
    },
    isTela(v) {
      return this.$route.name === v
    },
    handleResize() {
        this.window = window.innerWidth;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn
    },
  },

};

function limparCacheJS() {
  try {
    caches.keys().then(function (keyList) {
      return Promise.all(
        keyList.map(function (key) {
          if (key.indexOf("escala-medica") !== -1) {
            console.log(key);
            caches.open(key).then((cache) =>
              cache.keys().then((keys) => {
                for (let entry of keys) {
                  if (entry.url.indexOf("index.html") !== -1) {
                    console.log(entry);
                    cache.delete(entry);
                  }
                }
              })
            );
          }
        })
      );
    });
  } catch (e) {
    console.log("Falha ao limpar cache");
    console.log(e);
  }
}
</script>

<style scoped>
@media (min-width: 0px) {
  .main-panel-mobile {
    width: 100%;
    margin-top: 4em;
    display: block;
  }

  .desktop {
    display: none;
  }

  .main-panel {
    display: none;
  }
}

@media (min-width: 990px) {
  .main-panel-mobile {
    display: none;
  }

  .desktop {
    display: block;
  }

  .main-panel {
    display: block;
  }
}

.dropdown-container {
  display: flex;
  justify-content: center;
  width: 3em;
  line-height: 31px;
}

.dropdown-container:hover {
  background: rgba(255, 255, 255, 0.13);
  opacity: 1;
}

.dropdown-container i {
    font-size: 28px;
    color: #fff;
    opacity: .86;
}

.dropbtn {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
  border: none;
}

.dropdown {
  position: relative;
  margin: 0;
  padding: 1em 1em;
  display: flex;
  transition: 0.2s;
  z-index: 999 !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 3.3em;
  
  /* top: -0.04em; */
  top: 0;
  background-color: #4781ff;
  min-height: 100%;
  /* min-width: 160px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999 !important;
}

.dropdown-content p {
  width: 10em;
  padding: 12px 16px 0 16px;
  font-weight: bold;
}

.dropdown-content a {
  color: #fff;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  left: 50px;
}

.dropdown-content a:hover {
  background-color: #2f6aeb;
}

.dropdown:hover .dropdown-content {
  display: block;
}

</style>
