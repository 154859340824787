<template>
  <div style="min-height: 500px;" class="pos-criar-conta">

    <card v-if="conf && conf.mensagem">

      <template slot="header">
        <h4 class="card-title">Credenciamento</h4>
      </template>

      <b-container class="c-form">

        <b-row>

          <div class="col-md-12">
            <b-jumbotron>

              <template #header>
                <p class="display-4">{{conf.mensagem.pergunta}}</p>
              </template>

              <template #lead>
                {{ conf.mensagem.header }}
              </template>

              <hr class="my-4">

              <div v-html="conf.mensagem.body" style="max-height: 1000px; overflow-y:auto;"></div>

              <hr class="my-4">

              <div v-html="conf.mensagem.footer" style="max-height: 1000px; overflow-y:auto;"></div>

              <hr class="my-4">
              <b-button v-if="conf.mensagem.botaoContinuar" variant="primary" class="mr-2 btn-fill" size="md" @click.prevent="novoCredenciamento">{{conf.mensagem.botaoContinuar.label}}</b-button>
              <b-button v-if="conf.mensagem.botaoDesistir" variant="light" class="btn-fill" size="md" @click.prevent="naoPossuiEmpresa">{{conf.mensagem.botaoDesistir.label}}</b-button>

            </b-jumbotron>
          </div>

          <div class="col-md-7">
            <form autocomplete="off">
              <b-row class="mx-0">
                <div class="col-md-12" v-if="credenciamento">
                </div>
              </b-row>
            </form>
          </div>
        </b-row>

        <form>
          <select-empresa ref="selectEmpresa"
            @selecionou="(r) => { if (r.status === 'success') this.continuarNovoCredenciamento(r.empresa) }" />
          <div class="clearfix"></div>
        </form>

      </b-container>
    </card>


  </div>
</template>

<script>
import appUtils from '@/composables/utils';
import Card from 'src/components/Cards/Card.vue';
import AgendaService from '../../service/AgendaService';
import SelectEmpresa from '../comuns/SelectEmpresa';

export default {
  components: {
    Card,
    SelectEmpresa,
  },
  data() {
    return {
      show: false,
      showFrom: "",
      filaShow: [],
      empresa: '',
      idEmpresa: null,
      credenciamento: false,
      conf: null,
    }
  },
  async mounted() {

    const { toast } = appUtils(this)

    try {

      this.empresa = this.$route.params.empresa

      await this.buscarIdEmpresa()

      this.posCriarConta()

    } catch (e) {
      toast(e, 'Falha', 'danger')
    }

  },
  methods: {
    novoCredenciamento() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("novoCredenciamento");

      if (this.empresa) {
        this.continuarNovoCredenciamento();
      } else {

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idProfissional": dadosUsuario.idPessoa
        }

        AgendaService.buscaEmpresaCredenciamento(dadosConsulta)
          .then((resposta) => {
            this.empresas = resposta.data.obj
            if (this.empresas.length === 0) {
              toast("Nenhuma empresa disponível para credenciamento", "Falha", "danger")
            } else if (this.empresas.length === 1) {
              this.empresa = this.empresas[0].identificador
              this.continuarNovoCredenciamento(this.empresas[0].identificador);
            } else {
              this.$refs.selectEmpresa.abrir(this.empresas)
            }
            desativarShow("novoCredenciamento");
          })
          .catch((err) => {
            desativarShow("novoCredenciamento");
          });
      }
    },
    continuarNovoCredenciamento() {
      this.abrirCredenciamento(this.empresa, null)
    },
    abrirCredenciamento(empresa, id) {
      this.$router.push({ name: 'Credenciamento', params: { empresa: empresa }, query: { tp: btoa('PF'), ch: btoa(id), dest: btoa('ListCredenciamentoPF') } });
    },
    naoPossuiEmpresa() {
      this.$router.push({ name: 'Agenda' });
    },
    posCriarConta() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("posCriarConta")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"))

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": this.idEmpresa
      }

      AgendaService.posCriarConta(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data.obj
          if (retorno.destino === 'credenciamento') {            
            this.conf = retorno.conf
            console.log(this.conf)
            if(this.conf.mensagem){              
              this.credenciamento = true            
            }else{
              this.novoCredenciamento()
            }
          } else {
            this.$router.push({ name: retorno.destino })
          }

          desativarShow("posCriarConta")
        }).catch((err) => {
          desativarShow("posCriarConta")
          toast(err, 'Falha', 'danger')
        });

    },

    async buscarIdEmpresa() {

      try {

        if (this.empresa) {
          let dadosConsulta = {
            "identificador": this.empresa
          }

          const { toast, ativarShow, desativarShow } = appUtils(this)

          ativarShow("buscarEmpresa");

          await AgendaService.buscarEmpresaPeloIdentificador(dadosConsulta)
            .then((resposta) => {
              let retorno = resposta.data;
              desativarShow("buscarEmpresa");
              if (retorno.status === "SUCCESS") {
                this.idEmpresa = retorno.obj.id
              } else {
                if (retorno.mensagens.length > 0) {
                  toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
                }
              }
            }).catch((error) => {
              desativarShow("buscarEmpresa");
              toast(error, 'Falha', 'danger')
            });
        }

      } catch (e) {
        alert(e)
      }

    },
    limpar() {
      this.profissional.cpf = null
      this.profissional.email = null
      this.profissional.celular = null
      this.profissional.nome = null
      this.profissional.ufCrm = null
      this.profissional.crm = null
    },
  }
}

</script>
<style lang="scss">
.pos-criar-conta {

  .jumbotron {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .dados-parceiro p {
    margin-bottom: 0;
  }

}</style>