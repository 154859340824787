<template>
  <b-container fluid class="login-page">
    <div class="content">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-lg-3 col-md-6 col-sm-8">
          <p><span class="success">Atualizando versão. Aguarde...</span></p>
          <p><span class="success">Caso não atualize automaticamente, </span></p>
          <span class="success">Pressione CTRL+SHIFT+R ou clique no botão abaixo.</span>
          <span>              
            <div class="text-center pt-3">
              <b-button type="submit" variant="primary" class="btn btn-fill" size="lg" 
                @click.prevent="atualizarVersaoManual">Atualizar agora</b-button>                          
            </div>                              
          </span>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>

import AgendaService from '../service/AgendaService';

export default {
  components: {

  },
  data() {
    return {
      atualizarManual: false
    }
  },
  mounted() {
    // Atualiza a data atual a cada 10 segundos para comparação dinâmica
    setInterval(() => {
      this.atualizarVersaoManual()
    }, 10000);
  },
  methods: {    
    async atualizarVersaoManual() {      

      console.log('atualizando automático')
      await this.buscarVersao()      
      this.$router.go(0);
      var url = window.location.origin
      window.location = url
      window.location.reload(true)      
      atualizarVersao()

    },
    async buscarVersao() {
      await AgendaService.buscarVersao().then((resposta) => {
        let retorno = resposta ? resposta.data : null;
        if (retorno && retorno.status === "SUCCESS") {
          let user = JSON.parse(localStorage.getItem('dadosUsuario'));
          if (user) {
            user.versaoWeb = retorno.obj
            localStorage.setItem('dadosUsuario', JSON.stringify(user))
          } else {
            this.logout()
          }
        } else {
          this.logout()
        }
      }).catch((error) => {
        console.log(error)
        this.logout()
      })
    },
    logout() {
      console.log('logout refresbbuttom')
      this.$store.dispatch('auth/logout');
      this.$router.push({ name: 'Acesso' }); 
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  created() {
    
  },
}
  
function atualizarVersao() {
  limparCacheJS();
  window.location.href = window.location.href
  window.location.reload(true);
}

function limparCacheJS(){
    
    try {
      caches.keys().then(function(keyList) {
        return Promise.all(keyList.map(function(key) {
          if (key.indexOf('escala-medica') !== -1) {
            console.log(key)		  
            caches.open(key).then(cache => cache.keys()
            .then(keys => {
              for (let entry of keys) {
                if(entry.url.indexOf('index.html') !== -1 ){
                  console.log(entry)
                  cache.delete(entry)
                }    		
              }
            }));		  
          }
        }));
      })
    }catch (e) {
      console.log('Falha ao limpar cache')
      console.log(e)
    }
  }

</script>
<style>
.principal {
  margin-top: 200px;
}

.login-page .card {
  border-radius: 10px;
  /*padding-top: 30px;*/
  padding-bottom: 30px;
}

.login-page .content {
  padding-top: 12vh;
}
</style>