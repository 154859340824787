const getConselho = () => {
  
 return [
    {value: 'CRM', text: 'CRM'},
    {value: 'CRN', text: 'CRN'},
    {value: 'CRO', text: 'CRO'},
    {value: 'CRP', text: 'CRP'},
    {value: 'CREFITO', text: 'CREFITO'},
    {value: 'SEM', text: 'OUTRO'}]
}

export default getConselho