<template>
  <b-container class="c-form px-2" v-if="campos !== null">

    <b-modal header-bg-variant="light" header-text-variant="primary" footer-bg-variant="light" scrollable
      ref="help-documento" id="modal-help-documento" title="Documento" size="xl">

      <div class="d-block">
        <p>{{ helpDoc.texto }}</p>
      </div>

      <template #modal-footer="{ cancel }">
        <div class="w-100">
          <b-button size="md" variant="danger" class="btn-fill float-right" @click="cancel()">
            Fechar
          </b-button>
        </div>
      </template>
    </b-modal>

    <form>

      <b-row md="auto" v-if="showBackButton">
        <b-col>
          <b-button size="sm" class="btn btn-primary btn-fill float-right" @click.prevent="voltar">
            Voltar
          </b-button>
        </b-col>
      </b-row>

      <b-row v-if="obsReprovacao">
        <b-col>
        <b-alert variant="danger" show class="mt-3">
          <h5 class="mb-2"><i class="fas fa-exclamation-triangle mr-2"></i>Problemas encontrados:</h5>
          {{obsReprovacao}}
        </b-alert>
        </b-col>
      </b-row>

      <b-row md="auto" v-for="campo in campos" v-bind:key="campo.nome">

        <div class="col-md-12" v-if="campo.tipo === 'CABEÇALHO'" v-show="campo.visivel">
          <h3 class="card-title text-primary">{{ campo.titulo }}</h3>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'PARÁGRAFO'" v-show="campo.visivel">
          <div v-html="campo.conteudo" style="max-height: 1000px; overflow-y:auto;"></div>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'TEXTO CURTO'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :readonly="leitura" type="text" :placeholder="campo.titulo"
              @input="campo.conf && campo.conf.converterMaiusculo && campo.conf.converterMaiusculo === 'S' ? formatarMaiusculo(campo) : null"
              :maxlength="campo.maxLength !== null ? campo.maxLength : 500" v-model="campo.valor" />
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'TEXTO LONGO'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-textarea :readonly="leitura" v-model="campo.valor" :placeholder="campo.titulo" rows="2"
              max-rows="campo.maxLine" :maxlength="campo.maxLength !== null ? campo.maxLength : 500"></b-form-textarea>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'CNPJ'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :id="campo.nome" :readonly="leitura" type="text" :placeholder="campo.titulo" maxlength="18"
              v-mask="'##.###.###/####-##'" v-model="campo.valor" v-on:keyup="alteraCnpj($event)" />
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'CPF'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :readonly="leitura" type="text" :placeholder="campo.titulo" maxlength="14"
              v-mask="'###.###.###-##'" v-model="campo.valor" />
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'E-MAIL'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :readonly="leitura" type="email" :placeholder="campo.titulo" @input="formatarMinusculo(campo)"
              :maxlength="campo.maxLength !== null ? campo.maxLength : 500" v-model="campo.valor" />
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'TELEFONE'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :readonly="leitura" type="text" :placeholder="campo.titulo" maxlength="15"
              @cnahge="changeMascara($event)" v-mask="mascaraTelefone" v-model="campo.valor" />
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'DATA'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :readonly="leitura" type="date" v-model="campo.valor" />
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'MONETÁRIO'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-input :readonly="leitura" v-model="campo.valor" v-money="money" :placeholder="campo.titulo"
              size="sm" @click="$event.target.select()"></b-form-input>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'CRM'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <div class="row m-0 p-0">
              <div class="col-md-2 ml-0 pl-0">
                <b-form-select v-model="campo.valores[0]" :options="optionsConselho" :disabled="leitura"
                  placeholder="Conselho"></b-form-select>
              </div>
              <div class="col-md-5 ml-0 pl-0 custom-mt">
                <b-form-input :readonly="leitura" onkeydown="javascript: return event.keyCode == 69 ? false : true"
                  placeholder="Número" :maxlength="campo.maxLength !== null ? campo.maxLength : 9"
                  v-model="campo.valores[1]" />
              </div>
              <div class="col-md-5 mx-0 px-0 custom-mt">
                <b-form-select v-model="campo.valores[2]" :options="optionsUF" :disabled="leitura"
                  placeholder="UF"></b-form-select>
              </div>
            </div>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'RG'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <div class="row m-0 p-0">
              <div class="col-md-2 ml-0 pl-0">
                <b-form-input type="text" :readonly="leitura" placeholder="Letras e números" maxlength="50"
                  v-model="campo.valores[0]" @input="formatarMaiusculoNumeroRG(campo)" />
              </div>
              <div class="col-md-10 ml-0 pl-0 custom-mt">
                <b-form-input type="text" :readonly="leitura" placeholder="Orgão expedidor" maxlength="50"
                  v-model="campo.valores[1]" @input="formatarMaiusculoOrgaoRG(campo)" />
              </div>
            </div>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'ÚNICA RESPOSTA'" v-show="validarCondicaoExibir(campo)">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-radio v-for="opcao in campo.opcoes" v-bind:key="opcao.valor" :name="campo.nome" :disabled="leitura"
              v-model="campo.valor" :value="opcao.valor">{{ opcao.descricao }}</b-form-radio>

            <div v-if="campo.outro === 'S'">
              <b-form-radio :disabled="leitura" v-model="campo.valor" :name="campo.nome"
                value="#outro#">Outro</b-form-radio>
              <b-form-input v-if="campo.valor === '#outro#'" :readonly="leitura" type="text" placeholder="Informe aqui"
                :maxlength="campo.maxLength !== null ? campo.maxLength : 500" v-model="campo.valores[0]" />
            </div>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'MÚLTIPLAS RESPOSTAS'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-checkbox-group v-model="campo.valores" stacked>
              <b-form-checkbox v-for="opcao in campo.opcoes" v-bind:key="opcao.valor" :disabled="leitura"
                :value="opcao.valor">
                <div v-html="opcao.descricao" style="text-transform: none; max-height: 1000px; overflow-y:auto;"></div>
              </b-form-checkbox>
            </b-form-checkbox-group>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'UF'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-select v-model="campo.valor" :options="optionsUF" :disabled="leitura"></b-form-select>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'LISTA SUSPENSA'">
          <base-input :label="campo.titulo" class="mb-0">
            <b-form-select v-model="campo.valor" :readonly="leitura">
              <b-form-select-option v-for="opcao in campo.opcoes" v-bind:key="opcao.valor" :value="opcao.valor">{{
                opcao.descricao }}</b-form-select-option>
            </b-form-select>
          </base-input>
        </div>

        <div class="col-md-12 mx-0 px-0" v-if="campo.tipo === 'CEP'" v-show="campo.visivel">
          <div class="col-md-12">
            <base-input :label="campo.titulo" class="mb-0">
              <b-form-input :readonly="leitura" :id="campo.nome" v-model="campo.valor" type="text" maxlength="9"
                placeholder="CEP" v-mask="'#####-###'" v-on:keyup="buscarCep($event)" />
            </base-input>
          </div>

          <b-row class="mx-0">
            <div class="col-md-7">
              <base-input label="Logradouro" class="mb-0">
                <b-form-input :readonly="leitura" type="text" placeholder="Logradouro" maxlength="200"
                  v-model="campo.valores[1]" />
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Número" class="mb-0">
                <b-form-input :readonly="leitura" type="text" placeholder="Número" maxlength="50"
                  v-model="campo.valores[2]" />
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="Complemento" class="mb-0">
                <b-form-input type="text" :readonly="leitura" placeholder="Complemento" maxlength="50"
                  v-model="campo.valores[3]" />
              </base-input>
            </div>
          </b-row>
          <b-row class="mx-0">
            <div class="col-md-4">
              <base-input label="Bairro" class="mb-0">
                <b-form-input :readonly="leitura" type="text" placeholder="Bairro" maxlength="50"
                  v-model="campo.valores[4]" />
              </base-input>
            </div>
            <div class="col-md-5">
              <base-input label="Cidade" class="mb-0">
                <b-form-input readonly type="text" placeholder="Cidade" maxlength="50" v-model="campo.valores[5]" />
              </base-input>
            </div>
            <div class="col-md-3">
              <base-input label="UF" class="mb-0">
                <b-form-input readonly type="text" placeholder="UF" maxlength="2" v-model="campo.valores[6]" />
              </base-input>
            </div>
          </b-row>
        </div>

        <div class="col-md-12 mx-0 px-0" v-if="campo.tipo === 'DADOS_BANCARIOS'" v-show="campo.visivel">

          <div v-if="!campo.conf || !campo.conf.suprimirTitularidade || campo.conf.suprimirTitularidade !== 'S'"
            class="col-md-12">
            <base-input label="Titular da conta" class="mb-0">
              <b-form-input :readonly="leitura" :id="campo.nome" v-model="campo.valores[3]" type="text" maxlength="100"
                placeholder="Titular da conta" />
            </base-input>
          </div>

          <b-row class="mx-0">
            <div class="col-md-10">
              <base-input label="Banco" class="mb-0">
                <v-select :id="campo.nome" :disabled="leitura" v-model="campo.valor" :options="bancos"
                  @input="alterarBanco(campo.valor, campo.nome)" :reduce="banco => banco.codigo" label="descricao"
                  placeholder="Selecione o Banco" ref="selectBanco">
                  <span slot="no-options" @click="$refs.selectBanco.open = false">
                    Nenhum registro encontrado
                  </span>
                </v-select>
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Tipo de conta" class="mb-0">
                <b-form-select v-model="campo.valores[9]" :options="tiposConta" :disabled="leitura"
                  placeholder="Tipo de conta"></b-form-select>
              </base-input>
            </div>
          </b-row>

          <b-row class="mx-0">
            <div class="col-md-2">
              <base-input label="Agência" class="mb-0">
                <b-form-input :readonly="leitura" type="text" placeholder="Agência" maxlength="4"
                  v-model="campo.valores[4]" />
              </base-input>
            </div>
            <div class="col-md-1">
              <base-input label="DV" class="mb-0">
                <b-form-input :readonly="leitura" type="text" placeholder="Dígito" maxlength="1"
                  v-model="campo.valores[5]" />
              </base-input>
            </div>
            <div class="col-md-2">
              <base-input label="Nº da conta" class="mb-0">
                <b-form-input type="text" :readonly="leitura" placeholder="Nº da conta" maxlength="10"
                  v-model="campo.valores[6]" />
              </base-input>
            </div>
            <div class="col-md-1">
              <base-input label="DV" class="mb-0">
                <b-form-input :readonly="leitura" type="text" placeholder="Dígito" maxlength="1"
                  v-model="campo.valores[7]" />
              </base-input>
            </div>
            <div class="col-md-6">
              <base-input label="Chave PIX" class="mb-0">
                <b-row class="m-0 p-0">                  
                  <div class="m-0 p-0 col-md-8">                    
                    <b-form-input :readonly="leitura || campo.naoQueroInformar" type="text" placeholder="Chave PIX" maxlength="200"
                      v-model="campo.valores[8]" />
                  </div>           

                  <div class="d-flex align-items-center text col-md-4 custom-mt" style="align-items: center;">

                    <b-form-checkbox classe="classex" v-model="campo.naoQueroInformar" :disabled="leitura" 
                    @change="limparCampoPix(campo)">{{ campo.conf &&
                      campo.conf.tituloNaoInformar ? campo.conf.tituloNaoInformar : "Não tenho PIX ou não quero informar"
                      }}</b-form-checkbox>
                      
                  </div>   

                </b-row>
              </base-input>
            </div>
          </b-row>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'ARQUIVO'" v-show="campo.visivel">
          <base-input :label="campo.titulo" class="mb-0">

            <div class="row m-0" v-if="!leitura && campo.conf && campo.conf.ajudaDocumento">
              <div class="col-md-12 px-0 pt-0 pb-1">
                <span class="texto-ajuda" v-html="campo.conf.ajudaDocumento.texto"> </span>
              </div>
            </div>

            <b-input-group>
              <b-form-file v-if="!leitura" :id="campo.nome" :state="Boolean(campo.valor)" :placeholder="campo.titulo"
                drop-placeholder="Arraste o arquivo aqui..." accept=".jpg,.jpeg,.png,.pdf,.doc,.docx" multiple
                @change="submitFile">
              </b-form-file>
            </b-input-group>

            <div class="mt-" v-if="campo.idDocumento">
              {{ campo.pathFile ? campo.pathFile : '' }}
              <b-link class="ml-3 text-primary" href="#" @click="abrirArquivo(campo.idDocumento)">
                <b-icon icon="download" class="border" scale="2.0"></b-icon>
              </b-link>
            </div>

            <div v-else class="mt-0">
              <b-list-group v-for="(doc, index) in campo.documentos" :key="index">
                <b-list-group-item button class="d-flex justify-content-between align-items-center pb-0 pt-0">
                  <b-link class="text-primary text-truncate" @click="abrirArquivoLocal(campo.nome, doc.id)">
                    {{ doc.nome }}
                  </b-link>
                  <b-icon v-if="!leitura" icon="trash" variant="danger" class="border m-2"
                    @click="removerDocumento(campo.nome, doc.id)">teste</b-icon>
                </b-list-group-item>
              </b-list-group>
            </div>
          </base-input>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'ESPECIALIZACOES'" v-show="campo.visivel">
          <crud-especializacao
            @remove="($event) => { campo.especializacoes = campo.especializacoes.filter((e) => { return e.id !== $event }) }"
            :leitura="leitura" :especializacoes="campo.especializacoes" :showHeader="true" :showSalvar="false"
            :showExcluir="true" :campo="campo"></crud-especializacao>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'ESPECIALIZACAO'" v-show="campo.visivel">
          <card class="h-100 my-0">
            <template slot="header">
              <h4 class="card-title">{{ campo.titulo }}</h4>
            </template>
            <b-row>
              <div class="col-md-12 py-0">
                <especializacao :espec="campo.especializacoes[0]" :showSave="false" :showExcluir="false" :campo="campo"
                  :leitura="leitura"></especializacao>
              </div>
            </b-row>
          </card>
        </div>

        <div class="col-md-12" v-if="campo.tipo === 'ESPECIALIDADE'" v-show="campo.visivel">
          <Especialidade :leitura="leitura" :campo="campo" />
        </div>

      </b-row>

      <b-row>
        <slot name="acao">
        </slot>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import getConselho from '@/composables/getConselho'
import getUF from '@/composables/getUF'
import appUtils from '@/composables/utils'
import axios from 'axios'
import Card from 'src/components/Cards/Card.vue'
import { VMoney } from 'v-money'
import funcao from "../../components/Funcao"
import AgendaService from "../../service/AgendaService"
import CrudEspecializacao from "./CrudEspecializacao"
import Especialidade from './Especialidade'
import Especializacao from './Especializacao'
import Graduacao from "./Graduacao"

export default {
  components: {
    Card,
    CrudEspecializacao,
    Graduacao,
    Especializacao,
    Especialidade,
  },
  props: ['campos', 'leitura', 'showBackButton','obsReprovacao'],
  data() {
    return {
      x: '',
      show: false,
      showFrom: "",
      filaShow: [],
      optionsUF: getUF(),
      optionsConselho: getConselho(),
      tiposConta: [],
      bancos: [],
      mascaraTelefone: '(##)#####-####',
      helpDoc: { texto: '', imagens: [] },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
    }
  },
  directives: { money: VMoney },
  mounted() {
    this.carregarBancos()
    this.tiposConta = [{value: '', text: '', disabled: true}, {value: 'CC', text: 'Corrente'}, {value: 'PP', text: 'Poupança'}]
  },
  methods: {

    formatarMinusculo(campo) {
      campo.valor = campo.valor.replace(/[A-Z]/g, letra => letra.toLowerCase());
    },
    
    formatarMaiusculo(campo) {
      campo.valor = campo.valor.replace(/[a-z]/g, letra => letra.toUpperCase());
    },

    formatarMaiusculoNumeroRG(campo) {
      campo.valores[0] = campo.valores[0].replace(/[a-z]/g, letra => letra.toUpperCase());
    },

    formatarMaiusculoOrgaoRG(campo) {
      campo.valores[1] = campo.valores[1].replace(/[a-z]/g, letra => letra.toUpperCase());
    },

    validarCondicaoExibir(campo){            
      let exibir = campo.visivel
      if(exibir && campo.condicao){            
        campo.condicao.forEach(c => {
          let obj = this.campos.find((o) => o.nome === c.campo);
          if(!obj || obj.valor !== c.valor){
            exibir = false
            //campo.visivel = false
          }
        })
      }
      return exibir
    },
    limparCampoPix(campo) {
      if (campo.naoQueroInformar) {
        this.$set(campo.valores, 8, "")
      }
    },
    carregarBancos() {

      const { ativarShow, desativarShow } = appUtils(this)

      if (this.bancos.length == 0) {

        ativarShow("carregarBancos")

        AgendaService.buscarBancos()
          .then((resposta) => {
            this.bancos = resposta.data.obj;
            desativarShow("carregarBancos")
          })
          .catch((error) => {
            desativarShow("carregarBancos")
          });
      }

    },    
    submitFile(event) {

      const { toast } = appUtils(this)

      var campo = this.campos.find(c => c.nome === event.target.id);
      let index = 0;

      let lerArquivo = (file) => {        
        var reader = new FileReader();
        reader.onload = (e) => {
          var minId = campo.documentos.reduce((min, doc) => (doc.id < min ? doc.id : min), 0)
          minId -= 1;
          campo.documentos.push({
            "id": minId,
            "nome": file.name,
            "conteudo": e.target.result,
            "idTipo": null
          })
        };
        reader.readAsDataURL(file)
        reader.onerror = function (error) {
          console.log('Error: ', error)
        }
      };

      for (index = 0; index < event.target.files.length; index++) {
        var file = event.target.files[index];
        if(file.size > 10500000){
          toast(`Arquivo ${file.name} não pode ser usado. Limite permitido é 10 MB.`, 'Falha', 'danger')   
        }else{
          lerArquivo(file)
        }
      }

    },
    abrirArquivoLocal(idCampo, idDoc) {

      var campo = this.campos.find(c => c.nome === idCampo);
      var doc = campo.documentos.find(d => d.id === idDoc);

      if (doc.id < 0) {
        funcao.methods.downloadData(doc.arquivo, doc.nome);
      } else {
        this.abrirArquivo(doc.id)
      }

    },
    removerDocumento(idCampo, idDoc) {
      var campo = this.campos.find(c => c.nome === idCampo);
      campo.documentos = campo.documentos.filter((d) => { return d.id !== idDoc })
    },
    async buscarCep(event) {   

      const { toast } = appUtils(this)

      var campo = this.campos.find(c => c.nome === event.target.id);
      var self = this;
      var cep = event.target.value.replace('-', '')

      let valit = /^[0-9]{5}-[0-9]{3}$/.test(cep)
      let tamanhoString = cep.length
      if (tamanhoString == 8) {
      let urlBusca = "https://viacep.com.br/ws/" + cep + "/json/"
      
        try {
          let encontrou = false;
          let resposta = await axios.get(urlBusca);          
          if (resposta && resposta.data && resposta.data.localidade) {
            this.$set(campo.valores, 0, resposta.data.cep)
            campo.valores[1] = resposta.data.logradouro
            campo.valores[4] = resposta.data.bairro
            campo.valores[5] = resposta.data.localidade
            campo.valores[6] = resposta.data.uf
            encontrou = true;
          } else {            
            urlBusca = "https://brasilapi.com.br/api/cep/v1/" + cep
            resposta = await axios.get(urlBusca, {timeout: 3000});            
            if (resposta && resposta.data && resposta.data.city) {
              this.$set(campo.valores, 0, resposta.data.cep)
              campo.valores[1] = resposta.data.street
              campo.valores[4] = resposta.data.neighborhood
              campo.valores[5] = resposta.data.city
              campo.valores[6] = resposta.data.state
              encontrou = true;
            }
          }
          if(!encontrou){
            throw new Error("Cep não encontrato");
          }
        } catch (error) {
          this.$set(campo.valores, 0, cep)
          campo.valores[1] = ''
          campo.valores[4] = ''
          campo.valores[5] = ''
          campo.valores[6] = ''
          //console.log('Cep não encontrato')
          toast('Cep não encontrato', "Falha", "danger");
        }
      
      /*axios.get(urlBusca).then(endereco => {

          this.$set(campo.valores, 0, endereco.data.cep)
          campo.valores[1] = endereco.data.logradouro
          campo.valores[4] = endereco.data.bairro
          campo.valores[5] = endereco.data.localidade
          campo.valores[6] = endereco.data.uf

        }).catch(e => {
          console.log('Cep não encontrato')
        })
      */      

      }
    },
    alteraCnpj(event) {
      var cnpj = event.target.value.replace(/[^0-9]/ig, '');
      this.$emit('alterouCnpj', { 'campo': event.target.id, 'cnpj': cnpj })
    },
    changeMascara: function (e) {
      this.mascaraTelefone = e.length <= 14 ? '(##) ####-####' : '(##) #####-####'
    },
    alterarBanco(codigo, idCampo) {
      var campo = this.campos.find(c => c.nome === idCampo);
      if (codigo !== null) {
        var banco = this.bancos.find(b => b.codigo === codigo);
        this.$set(campo.valores, 0, banco.id)
        campo.valores[1] = banco.codigo
        campo.valores[2] = banco.descricao
      } else {
        this.$set(campo.valores, 0, null)
        campo.valores[1] = null
        campo.valores[2] = null
      }
    },
    abrirArquivo(idDoc) {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("abrirArquivo");
      AgendaService.download(idDoc)
        .then(resposta => {
          let ret = resposta.data
          if (ret.status === 'SUCCESS') {
            funcao.methods.download(ret.obj.conteudo, ret.obj.nome);
          } else {
            toast(data.mensagens[0].descricao, 'Falha', 'danger')
          }
          desativarShow("abrirArquivo");
        }).catch(error => {
          desativarShow("abrirArquivo");
          toast(error, "Falha", "danger")
        })
    },
    exibirHelpDocumento(conf) {
      console.log(conf)
      this.helpDoc.texto = conf.ajudaDocumento.texto
      this.helpDoc.imagens = conf.ajudaDocumento.imagens
      this.$bvModal.show("modal-help-documento")
    },
    abrirUrl(url, id) {
      this.$router.push({ path: url, params: { idPessoa: id } });
    },
    voltar() {
      this.$emit('fecharedit')
    },
  },


}

</script>
<style>
  .c-form {
    padding-bottom: 60px;
  }

  .custom-mt { margin-top: 0px; } /* Padrão */
    
  @media (max-width: 768px) {  /* md */
    .custom-mt { margin-top: 8px; } /* mt-4 equivalente */    
  }

</style>