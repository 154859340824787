<template>
  <div class="c-form">

    <b-modal ref="modal-historico" id="modal-historico" size="lg" title="Histórico" hide-footer
      header-bg-variant="secondary" header-text-variant="light">
      <div class="container-fluid">
        <b-row class="my-1">
          <div class="col-md-12">

            <b-table :fixed="true" :bordered="true" :items="historicos" :fields="fieldsHistorico" responsive="sm"
              head-variant="light" sticky-header stacked="md">

              <template v-slot:cell(data)="row">
                {{ strDateFormat(row.item.data, 'dd/MM/yyyy HH:mm:ss') }}
                </template>
              
            </b-table>
          </div>
        </b-row>
      </div>
    </b-modal>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') continuarConfirmar() }">
      <template v-slot:texto>{{ msgConfirma }}</template>
    </confirma>

    <div v-if="!edit">

      <card class="h-100">

        <template slot="header">
          <h4 class="card-title">Credenciamentos</h4>
        </template>

        <div class="row list-credenciamentos">

          <div class="col-md-2 filtro">
            <b-form-input type="text" placeholder="Nome/CRM/CPF" v-model="filter" v-on:keyup.enter="onEnter" />
          </div>

          <div class="col-md-2 filtro">
            <v-select v-model="idSituacao" :options="itensSituacao" :reduce="situacao => situacao.id" label="descricao"
              ref="select" placeholder="Selecione a situação">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
            </v-select>
          </div>

          <!-- div class="col-md-2 filtro">
            <v-select v-model="tipoFiltroData" :options="optionsTipoData" :reduce="tipo => tipo.code" placeholder="Tipo de data">              
            </v-select>
          </div !-->

          <div class="col-md-2 filtro">
            <b-form-datepicker id="dtInicio" v-model="dtInicio" size="sm" placeholder="Início do período" today-button
              reset-button close-button today-button-variant="primary" reset-button-variant="danger"
              close-button-variant="success" label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
              label-reset-button="Limpar" label-close-button="Fechar"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}">
            </b-form-datepicker>
          </div>

          <div class="col-md-2 filtro">
            <b-form-datepicker id="dtFim" v-model="dtFim" size="sm" placeholder="Fim do período" today-button
              reset-button close-button today-button-variant="primary" reset-button-variant="danger"
              close-button-variant="success" label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
              label-reset-button="Limpar" label-close-button="Fechar"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
            </b-form-datepicker>
          </div>

          <div class="col-md-2 filtro">
            <v-select v-model="tags" multiple :options="itensTag" label="descricao"
              ref="selectTag" placeholder="Selecione a tag">
              <span slot="no-options" @click="$refs.select.open = false">
                Nenhum registro encontrado
              </span>
            </v-select>
          </div>         

          <div class="col-md-2 h-100 text-center text-md-left filtro">
            
            <b-button size="sm" class="btn btn-primary btn-fill" @click="carregaDados">
              Buscar
            </b-button>

            <b-dropdown text="Relatórios" variant="primary" class="ml-2" size="sm">              
              <b-dropdown-item @click="gerarJson">Exportar para JSON</b-dropdown-item>
            </b-dropdown>

          </div>
          
        </div>

        <b-row>
          <b-col cols="12" class="pt-2">

            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="abrirCredenciamento">
              <template #cell(name)="row">
                {{ row.value.first }} {{ row.value.last }}
              </template>

              <template v-slot:cell(dtRegistro)="row">
                {{ strDateFormat(row.item.dtRegistro, 'dd/MM/yyyy') }}
              </template>

              <template v-slot:cell(dtCredenciamento)="row">
                {{ strDateFormat(row.item.dtCredenciamento, 'dd/MM/yyyy') }}
              </template>

              <template v-slot:cell(tags)="row">
                <div style="font-size: 0.6rem;">
                {{ getDscTags(row.item.tags) }}
              </div>
              </template>

              <template #cell(actions)="row">

                <b-button size="sm" @click="editar(row.item.id)" class="mr-1" variant="success" title="Abrir">
                  <b-icon icon="pencil-square" aria-hidden="true"></b-icon>
                </b-button>

                <b-button size="sm" @click="confirmaExcluir(row.item.id)" class="mr-1" variant="danger" title="Excluir">
                  <b-icon icon="trash" aria-hidden="true"></b-icon>
                </b-button>

                <b-button size="sm" @click="abrirHistorico(row.item.id)" class="mr-1" variant="warning" title="Histórico">
                  <b-icon icon="clock-history" aria-hidden="true"></b-icon>
                </b-button>

                <b-button v-if="row.item.situacao == 'CCD'" size="sm" @click="confirmaCancelar(row.item.id)" class="mr-1" variant="success" title="Cancelar credenciamento">
                  <b-icon icon="backspace" aria-hidden="true"></b-icon>
                </b-button>

              </template>

            </b-table>
          </b-col>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>

        </div>
      </card>

    </div>


    <div v-else>
      <edit-credenciamento @showOverlay="(status) => this.$emit('showOverlay', status)" :idCredenciamento="idCredenciamento" @fecharedit="() => this.edit = false"
        @executou="(r) => { if (r.status === 'success') this.atualizarRegistro(r.entity) }" />
    </div>

    <div class="clearfix"></div>

  </div>

</template>

<script>
import appUtils from '@/composables/utils'
import Card from 'src/components/Cards/Card.vue'
import funcao from "src/components/Funcao"
import AgendaService from '../../service/AgendaService'
import Confirma from '../comuns/Confirma'
import EditCredenciamento from './EditCredenciamento'

export default {
  components: {
    Card,
    EditCredenciamento,
    Confirma
  },
  data() {
    return {
      id: null,
      mensagemEvento: null,
      show: false,
      http: null,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      items: [],
      edit: false,
      idCredenciamento: null,
      itensSituacao: [],
      itensTag: [],
      tags: null,
      idSituacao: null,
      tipoFiltroData: 'CAD',
      dtInicio: null,
      dtFim: null,
      topoConfirma: '',
      msgConfirma: '',
      fields: [
        { key: 'contratante', label: 'Contratante', sortable: true, sortDirection: 'asc', thStyle: { width: "14%" } },
        { key: 'contratada', label: 'Contratada', sortable: true, sortDirection: 'asc', thStyle: { width: "14%" } },
        { key: 'nomeProfissional', label: 'Profissional', sortable: true, sortDirection: 'asc', thStyle: { width: "14%" } },
        { key: 'dscSituacao', label: 'Situação', sortable: true, sortDirection: 'asc', thStyle: { width: "12%" } },
        { key: 'dtRegistro', label: 'Data cad.', sortable: true, sortDirection: 'asc', thStyle: { width: "6%" } },
        { key: 'dtCredenciamento', label: 'Data credencia.', sortable: true, sortDirection: 'asc', thStyle: { width: "7%" } },
        { key: 'tags', label: 'Tags', sortable: true, sortDirection: 'asc', thStyle: { width: "8%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "10%" } }
      ],
      fieldsHistorico: [
      { label: 'Descrição', key: 'descricao', thStyle: { width: '60%' } },
      { label: 'Data', key: 'data', thStyle: { width: '20%' } },
      { label: 'Responsável', key: 'dscUsuario', thStyle: { width: '20%' } },
      ],
      historicos:[],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      optionsTipoData: [{ label: 'Cadastro', code: 'CAD' }, {label: 'Credenciamento', code: 'CRED'}, {label: 'Aprovação', code: 'APRO'}, {label: 'Dados básicos', code: 'PDB'}]
    }
  },
  computed: {
    hierarquia() {
      var path = ''
      this.dscPai.forEach((desc) => {
        path += (path != '' ? '/' : '') + desc
      })
      return path
    }
  },
  async mounted() {

    await this.inicializar()
    this.carregaDados()

    const ch = this.$route.query.ch
    if(ch){
      const id = await this.buscarIdCredenciamento(ch)
      this.editar(id)
    }

  },
  methods: {
    async inicializar() {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("inicializar");

      let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))

      let dadosConsulta = '{ ' +
        '"idUsuario": ' + dadosUsuario.idPessoa +
        ',"idTipo": 2' +
        '}'

      await AgendaService.recuperaSituacoesGeral(dadosConsulta)
        .then(resposta => {
          this.itensSituacao = resposta.data.obj
          let s = this.itensSituacao.find((s) => s.identificador == 'AGA')
          if (s) {
            this.idSituacao = s.id
          }
          desativarShow("inicializar");
        }).catch(error => {
          desativarShow("inicializar");
        })

        await this.buscarTags()

    },
    async buscarIdCredenciamento(id) {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarIdCredenciamento");

      return await AgendaService.buscarCredenciamentoPeloIdEncrypt(id)
        .then(resposta => {
          desativarShow("buscarIdCredenciamento");                    
          return resposta.data.obj.id
        }).catch(error => {
          console.log(error)
          desativarShow("buscarIdCredenciamento");
          toast(error, "Deu erro", "danger")
        })
    },
    voltar(id) {
      var index = this.idPai.indexOf(id)
      this.idPai.splice(index, this.idPai.length - index)
      this.dscPai.splice(index, this.idPai.length - index);
      this.carregaDados();
    },
    editar(id) {
      this.edit = true
      this.idCredenciamento = id
    },
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarCredenciamentos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let tags = this.tags ? this.tags.map(i => ({ id: (i.id <0 ? i.id*-1 : i.id), negacao: i.negacao })) : null

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "idSituacao": this.idSituacao,
        //"tipoData": this.tipoFiltroData,
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "filtro": this.filter,
        "tags": tags,
        "comTags": true
      }

      AgendaService.listaCredenciamento(dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length
          desativarShow("listarCredenciamentos");
        })
        .catch((err) => {
          desativarShow("listarCredenciamentos");
        });

    },    
    confirmaExcluir(id) {      
      this.id = id
      this.abreConfirmar('excluir', 'Confirma exclusão do credenciamento?')
    },
    confirmaCancelar(id) {      
      this.id = id
      this.abreConfirmar('cancelar', 'Confirma cancelamento do credenciamento?')
    },
    abreConfirmar(tipo, msg) {
      this.tipoConfirma = tipo;
      this.msgConfirma = msg
      this.$refs.conf.show()
    },
    continuarConfirmar() {      
      if (this.tipoConfirma === 'excluir') {
        this.excluir()
      } else if (this.tipoConfirma === 'cancelar') {
        this.cancelar()
      }
    },
    excluir() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("excluirCredenciamento");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dados = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.id,        
      }

      AgendaService.excluirCredenciamento(dados)
        .then(resposta => {
        let retorno = resposta.data;
        if (retorno.status === "SUCCESS") {
          this.items = this.items.filter((i) => { return i.id !== this.id })
          toast(retorno.mensagens[0].descricao, "Sucesso", "success")
        } else {
          if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
        }
        desativarShow("excluirCredenciamento");
      }).catch(error => {
        desativarShow("excluirCredenciamento");
        toast(error, "Ações do usuário", "danger")
      })
    },
    cancelar() {
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("cancelar");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dados = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.id,        
      }

      AgendaService.cancelarCredenciamento(dados)
        .then(resposta => {
        let retorno = resposta.data;
        if (retorno.status === "SUCCESS") {
          this.carregaDados()
          toast(retorno.mensagens[0].descricao, "Sucesso", "success")
        } else {
          if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
        }
        desativarShow("cancelar");
      }).catch(error => {
        desativarShow("cancelar");
        toast(error, "Ações do usuário", "danger")
      })
    },
    atualizarRegistro(entity) {
      let obj = this.items.find((i) => i.id === entity.id);
      if (obj !== null) {
        obj.idSituacao = entity.idSituacao
        obj.dscSituacao = entity.dscSituacao
        obj.tags = entity.tags
      }
    },
    abrirSelecionaProfissional() {
      const { toast } = appUtils(this)
      let idsEliminar = this.locais.locais.map(elem => elem.idLocal);
      this.$refs.selectLocal.abrir(idsEliminar)
    },
    adicionarProfissional(locais) {
      locais.forEach((item) => {

        let reg = {
          idLocal: item.id,
          dscLocal: item.descricao,
        }

        this.locais.locais.push(reg);

      });
    },
    gerarJson(){

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("exportarParaJson")

      let tags = this.tags ? this.tags.map(i => ({ id: (i.id <0 ? i.id*-1 : i.id), negacao: i.negacao })) : null

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "idSituacao": this.idSituacao,       
        "dtInicio": this.dtInicio,
        "dtFim": this.dtFim,
        "filtro": this.filter,
        "tags": tags
      }

      AgendaService.exportarCredenciamentos(dadosEnvio)
        .then((resposta) => {
          desativarShow("exportarParaJson")
          let retorno = resposta.data;                    
          if (retorno.status === "SUCCESS") {            
            var base64  = btoa(new TextEncoder().encode(JSON.stringify(retorno.obj)).reduce((acc, byte) => acc + String.fromCharCode(byte), ""));            
            funcao.methods.downloadBase64File(base64, "credenciamentos.json", "application/json");            
          } else {
            toast(retorno.mensagens[0].descricao, "Falha", "danger");
          }
        })
        .catch((error) => {
          console.log(error)
          desativarShow("exportarParaJson")
        });
    
    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    },
    abrirCredenciamento(record, index) {
      this.editar(record.id)
    },
    strDateFormat(dataStr, format) {
      if (dataStr !== null) {
        let data = new Date(dataStr + " (UTC-3)")
        return dateToStr(data, format);
      } else {
        return ""
      }
    },    
    getDscTags(tags) {            
        return tags ? tags.reduce((a, b) => { return a + (a!= '' ? '|' : '') + b.dscClassificacao }, '') : ''               
    },
    async buscarTags() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarTags");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "identificadoresTipoGeral": ['TGC'],
        comNegacao: "S"
      }

      await AgendaService.listaClassificacao(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.itensTag = retorno.obj
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("buscarTags");
        }).catch((error) => {
          desativarShow("buscarTags")
          toast(error, 'Falha', 'danger')
        });
    },
    /*atualizarTags() {
      this.tags = this.credenciamento.tags ? this.credenciamento.tags.map(a => a.idClassificacao) : []
    },*/
  
  abrirHistorico(id) {
      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarHistorico")

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": id
      }
      AgendaService.listaHistoricoCredenciamento(dadosConsulta)
        .then((resposta) => {
          this.historicos = resposta.data.obj;
          desativarShow("buscarHistorico")
          this.$bvModal.show("modal-historico");
        })
        .catch((error) => {
          desativarShow("buscarHistorico")
        });
    },
    onEnter: function() {
       this.carregaDados()
    }
  },
}

function dateToStr(date, format) {
  if (date !== undefined && date !== null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
  return "";
}

</script>
<style>

@media (max-width: 768px) {
  .list-credenciamentos .filtro {
    padding-top: 5px;
  }
}

.c-form {
  padding-bottom: 60px;
}

.list-credenciamentos .filtro{
  font-size: 0.7rem;
}

</style>