<template>
  <transition name="fade" mode="out-in" >
    <b-overlay :show="show" rounded="sm" z-index="100" class="container-main-js" >

        <template #overlay>
          <div class="text-center">
            <!-- b-icon icon="stopwatch" font-scale="3" variant="primary" animation="cylon"></b-icon>
            <p id="cancel-label">Aguarde...</p-->          
              <b-spinner variant="primary" small type="grow"></b-spinner>
              <span class="ml-3 text-primary">Aguarde...</span>
          </div>
        </template>        

      <auto-logout v-if="loggedIn"></auto-logout>
      
      <!-- div v-if="loggedIn && !versaoAtualizada()" !-->
      <div v-if="!versaoAtualizada">
        <refresh-button></refresh-button>        
      </div>
      <router-view v-else @showOverlay="showOverlay" ></router-view>

    </b-overlay>
  </transition>
</template>
<script>

import AgendaService from '../service/AgendaService';
import { BASE_CONSTANTS } from '../service/config';
import RefreshButton from './RefreshButton';

export default {
  components: {
    RefreshButton,
    },
  data() {
    return {
      show: false,      
      versaoAtualizada: true
    };
  },
  async mounted() {
    this.verificaVersao()    
    setInterval(() => {
      this.verificaVersao()
    }, 86400000);
  },
  methods: {
    showOverlay(status) {
      this.show = status
    },
    /*versao() {
        return this.$store.getters['auth/versao']
    },*/
    async verificaVersao() {              
      var versao = await this.buscarVersao()
      console.log('Última versão:' + versao +' Versão cache: ' + BASE_CONSTANTS.versao)
      this.versaoAtualizada = versao === BASE_CONSTANTS.versao
    },              
  async buscarVersao() {
      let versao = await AgendaService.buscarVersao().then((resposta) => {
        let retorno = resposta ? resposta.data : null;
        if (retorno && retorno.status === "SUCCESS") {
          return retorno.obj
        }else{
          return ''
        }
      }).catch((error) => {
        console.log(error)        
      })
      return versao
    },     
  },  
  computed: {
    loggedIn() {      
      return this.$store.state.auth.status.loggedIn
    }
  }  
}

</script>
<style>
  .container{
    padding: 0px;
  }

  @media (min-width: 991px){
    .container-main-js{
      height: calc(100% - 60px); /* subtrai header e footer */      
      overflow-y: auto;
    }
  }


  .containerFluid{
    padding: 0px;
  }
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .1s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
</style>
