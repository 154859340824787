<template>
  <div>

    <confirma ref="conf" @respondeu="(r) => { if (r === 'S') excluir(0) }">
      <template v-slot:texto>Confirma exclusão do local?</template>
    </confirma>

    <div v-if="!edit">

      <card class="h-100">

        <template slot="header">
          <h4 class="card-title">Credenciamentos</h4>
        </template>

        <div class="row list-credenciamento-pf">

          <div class="col-md-6 filtro">
            <b-form-group label="Filtrar: " label-for="filter-input" label-cols-sm="1" label-align-sm="left"
              label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Digite aqui sua pesquisa"
                  v-on:keydown.enter="buscar"></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>

          <div class="col-md-6 h-100 text-center text-md-left filtro">
            <b-button class="mr-1 btn-fill" size="sm" variant="primary" @click="novoCredenciamento()">
              <b-icon icon="file-earmark" aria-hidden="true"></b-icon> Novo credenciamento
            </b-button>
          </div>

        </div>

        <b-row>
          <b-col cols="12" class="pt-2">

            <b-table empty-text="Nenhum registro" :items="items" :fields="fields" :fixed="true" :bordered="true"
              responsive="true" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc" :sort-direction="sortDirection" stacked="md" show-empty sticky-header="500px"
              small hover @row-clicked="abrirLinha">
              <template #cell(name)="row">
                {{ row.value.first }} {{ row.value.last }}
              </template>

              <template v-slot:cell(dtCredenciamento)="row">
                {{ strDateFormat(row.item.dtCredenciamento, 'dd/MM/yyyy') }}
              </template>

              <template #cell(actions)="row">

                <b-button v-if="row.item.situacao === 'PDB' || row.item.situacao === 'PRE'" size="sm" @click="editar(row.item)" class="mr-1" variant="success" title="Editar">
                  <b-icon icon="pencil-square" aria-hidden="true" ></b-icon>
                </b-button>

                <b-button v-if="row.item.situacao === 'CCD' && row.item.idDocTermo" size="sm" @click="abrirTermo(row.item.id)" class="mr-1" variant="primary" title="Termo">                  
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                </b-button>
              </template>

            </b-table>

          </b-col>

        </b-row>

        <div class="row">

          <div class="col-md-2 pl-2">

            <b-form-group label="Mostrar:" label-for="per-page-select" label-cols-sm="6" label-cols-md="4"
              label-cols-lg="3" label-align-sm="left" label-size="sm">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>

          </div>

          <div class="col-md-10 pr-2">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill"
              size="sm"></b-pagination>
          </div>

        </div>
      </card>

    </div>

    <div v-else>

    </div>

    <select-empresa titulo="Selecione a empresa para a qual deseja se credenciar" ref="selectEmpresa" @selecionou="(r) => { if (r.status === 'success') this.continuarNovoCredenciamento(r.empresa) }" />
    <div class="clearfix"></div>
  </div>
</template>

<script>
import appUtils from '@/composables/utils'
import Card from 'src/components/Cards/Card.vue'
import funcao from "src/components/Funcao"
import AgendaService from '../../service/AgendaService'
import Confirma from '../comuns/Confirma'
import SelectEmpresa from '../comuns/SelectEmpresa'


export default {
  components: {
    Card,
    Confirma,
    SelectEmpresa,
  },
  data() {
    return {
      id: null,
      mensagemEvento: null,
      show: false,
      showFrom: "",
      filaShow: [],
      configuracao: null,
      items: [],
      idPai: [],
      dscPai: [],
      idLocalPai: null,
      edit: false,
      idLocal: null,
      empresas: null,
      fields: [
        { key: 'contratante', label: 'Contratante', sortable: true, sortDirection: 'asc', thStyle: { width: "25%" } },
        { key: 'contratada', label: 'Contratada', sortable: true, sortDirection: 'asc', thStyle: { width: "30%" } },
        { key: 'dscSituacao', label: 'Situação', sortable: true, sortDirection: 'asc', thStyle: { width: "25%" } },        
        { key: 'dtCredenciamento', label: 'Data credencia', sortable: true, sortDirection: 'asc', thStyle: { width: "10%" } },
        { key: 'actions', label: 'Ação', thStyle: { width: "10%" } }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
    }
  },
  computed: {
    hierarquia() {
      var path = ''
      this.dscPai.forEach((desc) => {
        path += (path != '' ? '/' : '') + desc
      })
      return path
    }
  },
  async mounted() {

    await this.carregaDados()

    /*if (this.$route.params.iniciarPreenchimento) {      
      this.novoCredenciamento()
    }*/

    if (this.items.length === 0) {
      this.novoCredenciamento()
    }

  },
  methods: {            
    async carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("listarCredenciamentos");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idProfissional": dadosUsuario.idPessoa
      }

      await AgendaService.listaCredenciamentoPF(dadosConsulta)
        .then((resposta) => {
          this.items = resposta.data.obj
          this.totalRows = this.items.length          
          desativarShow("listarCredenciamentos");
        })
        .catch((err) => {
          desativarShow("listarCredenciamentos");
        });

    },
    buscar() {
      this.idPai = [];
      this.carregaDados();
    },
    novoCredenciamento() {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("novoCredenciamento");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idProfissional": dadosUsuario.idPessoa
      }

      AgendaService.buscaEmpresaCredenciamento(dadosConsulta)
        .then((resposta) => {
          this.empresas = resposta.data.obj

          if (this.empresas.length === 0) {
            toast("Nenhuma empresa disponível para credenciamento", "Falha", "danger")
          } else if (this.empresas.length === 1) {
            this.continuarNovoCredenciamento(this.empresas[0]);
          } else {
            this.$refs.selectEmpresa.abrir(this.empresas)  
          }

          desativarShow("novoCredenciamento");
        })
        .catch((err) => {
          desativarShow("novoCredenciamento");
        });
    },

    continuarNovoCredenciamento(v) {      
      this.empresa = v.identificador      
      this.abrirCredenciamento(this.empresa, null)
    },
    confirmaExcluir(id) {
      this.id = id
      this.$refs.conf.show()
    },
    abrirLinha(record, index) {      
      if (record.situacao === 'PDB' || record.situacao === 'PRE') {
        this.editar(record)
      }
    },    
    editar(item) {      
      this.abrirCredenciamento(item.empresa, item.id)
    },
    abrirCredenciamento(empresa, id) {
      this.$router.push({ name: 'Credenciamento', params: { empresa: empresa}, query: { tp: btoa('PF'), ch: btoa(id), dest: btoa('Credenciamentoi') } });      
    },
    atualizarRegistro(entity) {
      let index = this.items.indexOf(this.items.find((i) => i.id === entity.id));
      if (index >= 0) {
        this.items.splice(index, 1, entity);
      } else {
        this.items.push(entity);
      }
    },
    abrirSelecionaProfissional() {
      const { toast } = appUtils(this)
      let idsEliminar = this.configuracao.profissionais.map(elem => elem.id);
      this.$refs.selectProfissional.abrir(idsEliminar)
    },
    adicionarProfissional(regs) {
      regs.forEach((item) => {

        let reg = {
          idPessoa: item.id,
          crm: item.crm,
          cpf: item.cpf,
          nome: item.nome,
          deleted: 'N'
        }

        this.configuracao.profissionais.push(reg);

      });
    },
    abrirTermo(id) {

      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("abrirTermo");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": id
      }

      AgendaService.downloadTermo(dadosConsulta)
        .then((resposta) => {

          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            var base64 = retorno.obj;
            funcao.methods.downloadPdf(base64);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("abrirTermo");
        })
        .catch((err) => {
          desativarShow("abrirTermo");
        });

    },
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.deleted === 'S') return 'table-danger'
    },
    strDateFormat(dataStr, format) {
      if (dataStr !== null) {
        let data = new Date(dataStr + " (UTC-3)")
        return dateToStr(data, format);
      } else {
        return ""
      }
    },
  }
}

function dateToStr(date, format) {
  if (date !== undefined && date !== null) {
    let strDate = format
      .replace("yyyy", date.getFullYear())
      .replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
      .replace("dd", date.getDate().toString().padStart(2, "0"))
      .replace("HH", date.getHours().toString().padStart(2, "0"))
      .replace("mm", date.getMinutes().toString().padStart(2, "0"))
      .replace("ss", date.getSeconds().toString().padStart(2, "0"));
    return strDate;
  }
  return "";
}
</script>
<style lang="scss">

  @media (max-width: 768px) {
    .list-credenciamento-pf .filtro {
      padding-top: 5px;
    }
  }


.list-credenciamento-pf {
  .filtro {
    font-size: 0.7rem;
  }
}

</style>