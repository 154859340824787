import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import store from "./store/store";

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main';

//Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// router setup
import routes from './routes/routes';
//import managertoken from './store/managertoken'

import './registerServiceWorker';

//import Vuetify from 'vuetify'
//import vuetify from '@/plugins/vuetify'
//import DaySpanVuetify from 'dayspan-vuetify'
import vSelect from 'vue-select';
//import modal from "vue-js-modal"

import VueMask from 'v-mask';
Vue.use(VueMask)

import PortalVue from 'portal-vue';
Vue.use(PortalVue)

import VueToast from 'vue-toast-notification';
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

Vue.config.productionTip = false

// plugin setup
Vue.use(VueRouter)

//Vue.use(modal, { dialog: true, dynamic: true });

Vue.use(LightBootstrap)

//Bootstrap
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue';
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue';
Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue';
Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import { VBScrollspyPlugin } from 'bootstrap-vue';
Vue.use(VBScrollspyPlugin)

// This imports the dropdown and table plugins
import { DropdownPlugin, TablePlugin } from 'bootstrap-vue';
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)

import money from 'v-money';
Vue.use(money, {precision: 2, decimal: ',', prefix: 'R$', thousands: '.'})

Vue.component('v-select', vSelect)

Vue.component('auto-logout', require('./components/AutoLogout.vue').default);

//import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css';
//import 'dayspan-vuetify/dist/lib/dayspan-vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import jwt_decode from "jwt-decode";
import 'vue-select/dist/vue-select.css';

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {

  let userPermissions = []
  let token = localStorage.getItem('authorization')
  
  if (token === null || token === undefined || token === '' || token === 'null') {
    let isAllowed = false;    
    if (to.meta.permissions && to.meta.permissions.length > 0) {            
      isAllowed = to.meta.permissions.includes("LOGOFF")
    }

    if (!isAllowed) {
    //if (to.name !== 'Acesso' && to.name !== 'Suporte') {      
      //next('/login')      
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      })
    }
  } else {    

    //let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
    
    let decoded = jwt_decode(token)
    userPermissions = decoded.authorities        
    if (to.meta.permissions && to.meta.permissions.length > 0) {

      let isAllowed = to.meta.permissions.includes("LOGOFF")
      if (!isAllowed) {
        to.meta.permissions.forEach((value) => {          
          isAllowed = isAllowed || store.getters['auth/hasAccess'](value)
        })
      }
      //let isAllowed = to.meta.permissions.includes("LOGOFF") || userPermissions.some(p => to.meta.permissions.includes(p))            
      if (!isAllowed) return next('/denied')
    }    

  }  
  next()
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
})

//Vue.use(Vuetify);

/*Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor: () => '#1976d2'
  }
});*/

Vue.config.productionTip = false;