<template>
  <card>
    <h4 slot="header" class="card-title"> Credenciamento </h4>
    <form>

      <div class="col-md-12" v-if="credenciamento && credenciamento.empresaCredenciada">
        <p class="text-danger">{{ credenciamento.msgEmpresaCredenciada }}</p>
      </div>

      <campos-formulario v-if="credenciamento && credenciamento.formulario" :campos="credenciamento.formulario.campos"
        :leitura=true :showBackButton=true @fecharedit="() => this.$emit('fecharedit')" @alterouCnpj="(r) => { this.alterouCnpj(r) }">

        <template slot="acao">

          <div class="col-md-12 mx-0" v-if="credenciamento.idDocumentoTermo">
            <span class="h4">Termo de credenciamento</span>
            <b-link class="ml-3 text-primary" href="#" @click="abrirArquivo(credenciamento.idDocumentoTermo)">
              <b-icon icon="download" class="border" scale="2.0"></b-icon>
            </b-link>
          </div>
          <div class="col-md-12" style="min-height: 150px;">
            <base-input label="Motivo" v-show="reprovando">
              <b-form-textarea id="textarea" v-model="obsReprovacao" placeholder="Informe o motivo da reprovação..."
                rows="4" max-rows="10"></b-form-textarea>
            </base-input>
          </div>

          <div class="col-md-12 mx-0">
            <b-form-checkbox-group v-model="tags" :options="listaTags" name="flavour-2a" stacked></b-form-checkbox-group>
          </div>

          <div class="col-md-12 btn-criar text-center" v-if="credenciamento.identificadorSituacao === 'AGA'">

            <loading :show="show">
              <b-button v-if="!reprovando" class="btn btn-success btn-fill " @click.prevent="aprovar">
                Aprovar
              </b-button>
              <b-button class="btn btn-danger btn-fill ml-1" @click.prevent="reprovar">
                {{ !reprovando ? 'Reprovar' : 'Confirmar reprovação' }}
              </b-button>
              <b-button v-if="reprovando" class="btn btn-fill ml-1" @click.prevent="cancelarReprovar">
                Cancelar
              </b-button>
            </loading>

          </div>
          <div class="col-md-12 text-center" v-else>

            <loading :show="show">
              <b-button class="btn btn-success btn-fill " @click.prevent="atualizar">
                Atualizar
              </b-button>
            </loading>

          </div>
        </template>

      </campos-formulario>

      <b-container v-else-if="credenciamento && credenciamento.nomeProfissional" class="c-form px-2">

        <b-row md="auto">
          <b-col>
            <b-button size="sm" class="btn btn-primary btn-fill float-right" @click.prevent="voltar">
              Voltar
            </b-button>
          </b-col>
        </b-row>

        <b-row>
          <div class="col-md-3">
            <b-form-group label="cpf" label-for="input-cpf">
              <b-form-input v-mask="'###.###.###-##'" :readonly="true" id="input-cpf" type="text" placeholder="CPF"
                v-model="credenciamento.cpfProfissional" />
            </b-form-group>
          </div>
        </b-row>

        <b-row>
          <div class="col-md-12">
            <b-form-group label="Nome" label-for="input-nome">
              <b-form-input :readonly="true" id="input-nome" type="text" placeholder="Nome"
                v-model="credenciamento.nomeProfissional" />
            </b-form-group>
          </div>
        </b-row>

        <b-row>
          <div class="col-md-2">
            <b-form-group label="CRM" label-for="input-uf-crm">
              <b-form-input :readonly="true" id="input-uf-crm" type="text" placeholder="CRM"
                v-model="credenciamento.crmCompleto" />
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group label="E-mail" label-for="input-email">
              <b-form-input :readonly="true" id="input-email" type="text" placeholder="Email"
                v-model="credenciamento.emailProfissional" />
            </b-form-group>
          </div>

          <div class="col-md-2">
            <b-form-group label="Telefone" label-for="input-telefone">
              <b-form-input :readonly="true" id="input-telefone" type="text" placeholder="Telefone"
                v-mask="credenciamento.telefoneProfissional && credenciamento.telefoneProfissional.length <= 14 ? '(##) ####-####' : '(##) #####-####'"
                v-model="credenciamento.telefoneProfissional" />
            </b-form-group>
          </div>
        </b-row>

      </b-container>


    </form>

  </card>
</template>

<script>

import appUtils from '@/composables/utils'
import axios from 'axios'
import Card from 'src/components/Cards/Card.vue'
import funcao from "../../components/Funcao"
import Loading from '../../layout/Loading'
import AgendaService from '../../service/AgendaService'
import { BASE_CONSTANTS } from '../../service/config'
import CamposFormulario from '../comuns/CamposFormulario'

export default {
  props: ['idCredenciamento'],
  components: {
    Card,
    CamposFormulario,
    Loading
  },
  data() {
    return {
      http: null,
      show: false,
      showFrom: "",
      filaShow: [],
      credenciamento: null,
      obsReprovacao: null,
      reprovando: false,
      listaTags: [],
      tags: []
    }
  },
  mounted() {

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"', ''),
        'Content-Type': 'application/json',
        'Accept': '*/*'
      }
    })
    if (this.idCredenciamento > 0) {
      this.carregaDados();
    }
  },
  methods: {
    carregaDados() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("carregaDados");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "id": this.idCredenciamento,
        "comTags": true
      }

      this.buscarTags()

      AgendaService.abrirCredenciamento(this.http, dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            this.credenciamento = retorno.obj
            this.credenciamento.formulario.campos.filter((c) => { return c.tipo == 'CNPJ' })
                    .forEach(c => { 
                      this.alterouCnpj(
                        { 'campo': c.nome, 'cnpj': c.valor }
                      )
                    })
            this.atualizarTags()
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("carregaDados");
        }).catch((error) => {
          desativarShow("carregaDados");
          toast(error, 'Falha', 'danger')
        });
    },
    abrirArquivo(idDoc) {
      
      const { toast, ativarShow, desativarShow } = appUtils(this)
      ativarShow("abrirArquivo");

      AgendaService.download(idDoc)
        .then(resposta => {
          let ret = resposta.data
          if (ret.status === 'SUCCESS') {
            funcao.methods.download(ret.obj.conteudo, ret.obj.nome);
          } else {
            toast(data.mensagens[0].descricao, 'Falha', 'danger')
          }
          desativarShow("abrirArquivo");
        }).catch(error => {
          desativarShow("abrirArquivo");
          toast(error, "Falha", "danger")
        })
    },
    voltar() {
      this.$emit('fecharedit')
    },
    aprovar() {
      const { toast } = appUtils(this)
      this.show = true

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idCredenciamento": this.idCredenciamento,
        "idsTag": this.tags,
      }

      AgendaService.aprovarCredenciamento(this.http, dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          this.show = false
        }).catch((error) => {
          this.show = false
          toast(error, 'Falha', 'danger')
        });
    },
    reprovar() {

      if (!this.reprovando) {
        this.reprovando = true
        return
      }

      const { toast } = appUtils(this)
      this.show = true

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idCredenciamento": this.idCredenciamento,
        "obs": this.obsReprovacao
      }

      AgendaService.reprovarCredenciamento(this.http, dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": {
                "id": retorno.obj.id,
                "idProfissional": retorno.obj.idProfissional,
                "nomeProfissional": retorno.obj.nomeProfissional,
                "idSituacao": retorno.obj.idSituacao,
                "dscSituacao": retorno.obj.dscSituacao,
                "dtRegistro": retorno.obj.dtRegistro,
                "dtCredenciamento": retorno.obj.dtCredenciamento
              }
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          this.show = false
        }).catch((error) => {
          this.show = false
          toast(error, 'Falha', 'danger')
        });
    },
    cancelarReprovar() {
      this.reprovando = false
    },
    atualizar() {
      const { toast } = appUtils(this)
      this.show = true

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosEnvio = {
        "idUsuario": dadosUsuario.idPessoa,
        "idCredenciamento": this.idCredenciamento,
        "idsTag": this.tags,
      }

      AgendaService.atualizarCredenciamento(dadosEnvio)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {
            let ret = {
              "status": "success",
              "entity": retorno.obj
            }
            this.$emit('executou', ret)
            toast(retorno.mensagens[0].descricao, "Sucesso", "success")
            var v = this
            setTimeout(function () {
              v.voltar()
            }, 2000);
          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          this.show = false
        }).catch((error) => {
          this.show = false
          toast(error, 'Falha', 'danger')
        });
    },
    buscarTags() {

      const { toast, ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarTags");

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = {
        "idUsuario": dadosUsuario.idPessoa,
        "idEmpresa": dadosUsuario.grupo.empresa.id,
        "identificadoresTipoGeral": ['TGC']
      }

      AgendaService.listaClassificacao(dadosConsulta)
        .then((resposta) => {
          let retorno = resposta.data;
          if (retorno.status === "SUCCESS") {

            retorno.obj.forEach((item) => {
              this.listaTags.push(
                {
                  "text": item.descricao,
                  "value": item.id
                }
              )
            });

          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }
          desativarShow("buscarTags");
        }).catch((error) => {
          desativarShow("buscarTags");
          toast(error, 'Falha', 'danger')
        });
    },
    atualizarTags() {
      this.tags = this.credenciamento.tags ? this.credenciamento.tags.map(a => a.idClassificacao) : []
    },
    async alterouCnpj(r) {
            
      var campoCnpj = this.credenciamento.formulario.campos.find(c => c.nome === r.campo)
      
      if (campoCnpj.conf != null && campoCnpj.conf.validarCredenciamento) {
        var cred = null
        var cnpj = r.cnpj ? r.cnpj.replace(/[^0-9]/ig, '') : '';
        let tamanhoString = cnpj.length
        if (tamanhoString == 14) {
          cred = await this.buscarCredenciamento(cnpj)
        }

        var comCredenciamento = cred != null && cred.situacao == 'CCD'
        if (campoCnpj.conf.camposRemoverCredenciamento != null) {
          campoCnpj.conf.camposRemoverCredenciamento.forEach((cr) => {
            var campo = this.credenciamento.formulario.campos.find(c => c.nome === cr)
            if (campo) {
              campo.visivel = !comCredenciamento
            }
          });
        }

        if (campoCnpj.conf.camposAdicionarCredenciamento != null) {
          campoCnpj.conf.camposAdicionarCredenciamento.forEach((cr) => {
            var campo = this.credenciamento.formulario.campos.find(c => c.nome === cr)
            if (campo) {
              campo.visivel = comCredenciamento
            }
          });
        }
      }      
    },
    async buscarCredenciamento(cnpj) {

      const { ativarShow, desativarShow } = appUtils(this)

      ativarShow("buscarCredenciamento")

      let dadosConsulta = {
        "idEmpresa": this.credenciamento.idEmpresa,
        "idsEliminar": [this.credenciamento.id],
        "cnpj": cnpj
      }

      return await AgendaService.listaCredenciamento(dadosConsulta)
        .then((resposta) => {
          desativarShow("buscarCredenciamento")
          var resp = resposta.data          
          return resp.status == 'SUCCESS' && resp.obj.length > 0 ? resp.obj[0] : null
        })
        .catch((error) => {
          desativarShow("buscarCredenciamento")
        });
    },
  }

}
</script>

<style>
.box-color {
  border-width: 0;
  border-style: solid;
  border-radius: 5px;
  box-sizing: border-box;
  height: 40px;
  width: 100px;
  cursor: pointer;
}
</style>