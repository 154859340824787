<template>

</template>

<script>

import axios from 'axios'
import AgendaService from '../service/AgendaService'
import { BASE_CONSTANTS } from '../service/config'

export default {

    components: {
                
    },
    data() {
        return {
            
        }
    },

    mounted() {
        
    },
    methods: {
        http(){
            return axios.create({
            baseURL: BASE_CONSTANTS.urlBase,
            headers: {
                'Authorization': 'bearer ' + String(localStorage.getItem('authorization')).replaceAll('"',''),
                'Content-Type': 'application/json',
                'Accept': '*/*'
            }})
        },
        possuiPermissao(menu, permissao){        
            let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));            
                let perm = dadosUsuario.grupo.permissoes.filter(function(elem){      
                if(elem.menu == menu && elem.permissao == permissao) return elem;
                });
            return perm !== null && perm.length>0 ? perm[0].nivel : 'E';  
        },
        downloadExcel(data) {
            var temp = 'data:application/vnd.ms-excel;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.xlsx";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadTxt(data) {
            var temp = 'data:text/plain;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.txt";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadJson(data) {
            var temp = 'data:text/plain;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.json";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadPdf(data) {            
            var temp = 'data:application/pdf;base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = "arquivo.pdf";
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        download(data, fileName) {                        
            var parts = fileName.split('.');            
            var ext = parts[parts.length - 1];
            var type = ext;
            if (ext === 'xlsx' || ext === 'xls') type = 'vnd.ms-excel';
            if (ext === 'txt') type = 'text/plan';
            var temp = 'data:application/'+type+';base64,'+encodeURIComponent(data);  
            var download = document.createElement('a');  
            download.href = temp;  
            download.download = fileName;
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadData(data, fileName) {                                    
            var download = document.createElement('a');  
            download.href = data;  
            download.download = fileName;
            document.body.appendChild(download);  
            download.click();
            document.body.removeChild(download);
        },
        downloadBase64File(base64String, fileName, mimeType) {
            // Decodifica o Base64 para um Array de Bytes
            const byteCharacters = atob(base64String);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);

            // Cria um Blob com o tipo MIME correto
            const blob = new Blob([byteArray], { type: mimeType });

            // Cria um link para download e dispara o clique automaticamente
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();

            // Remove o link após o download
            document.body.removeChild(link);
        },
        async exportarHmtj(ids){
            let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
            let dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa +
            ',"ids":[' + ids + ']' +        
            '}';      

            return AgendaService.exportarRelatorioFinanceiroHmtj(this.http(), dadosConsulta)
            
        },
        
        async alterarSituacaoFechamento(idFechamento, idSituacaoFechamento) {
            this.show = true            
            let dadosUsuario = JSON.parse(localStorage.getItem('dadosUsuario'))
            let dadosPost = '{ "idFechamento": ' + idFechamento +
            ', "idSituacao":' + idSituacaoFechamento + ' '+
            ', "idUsuario": ' +dadosUsuario.idPessoa + '}';
            return AgendaService.alterarSituacaoFechamentoAgenda(this.http(), dadosPost)
        },
    }

}
</script>
<style>
</style>